import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA, LAY_DANH_SACH_KHACH_HANG_SAGA, LAY_DANH_SACH_KHACH_HANG_THEO_KEYWORD_SAGA, SET_DANH_SACH_KHACH_HANG, THEM_KHACH_HANG_SAGA, XOA_KHACH_HANG_SAGA } from '../types/KhachHangType';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { Notification } from '../../utils/Notifications/Notification';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { khachHangService } from '../../services/khachHangService';
import { CLEAN_CONTENT_DRAWER } from '../types/GlobalType';
import { SET_KHACH_HANG_DON_HANG } from '../types/DonHangType';


function* layDanhSachKhachHangSaga(action) {
    const { page, pageSize, keyword } = action?.payload;
    yield put({
        type: DISPLAY_LOADING
    });

    try {
        const { data, status } = yield call(() => {
            return khachHangService.layDanhSachKhachHangAPI(page, pageSize, keyword);
        })
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_KHACH_HANG,
                danhSachKhachHang: data?.data?.data,
                total: data?.data?.totalCount
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách khách hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachKhachHangSaga() {
    yield takeLatest(LAY_DANH_SACH_KHACH_HANG_SAGA, layDanhSachKhachHangSaga)
}

function* layDanhSachKhachHangTheoKeywordSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    });

    try {
        const { data, status } = yield call(() => {
            // return khachHangService.layDanhSachKhachHangTheoKeywordAPI(action?.keyword);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_KHACH_HANG,
                danhSachKhachHang: data?.data
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách khách hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachKhachHangTheoKeywordSaga() {
    yield takeLatest(LAY_DANH_SACH_KHACH_HANG_THEO_KEYWORD_SAGA, layDanhSachKhachHangTheoKeywordSaga)
}

function* themKhachHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return khachHangService.themKhachHangAPI(action?.newKhachHang);
        });

        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: LAY_DANH_SACH_KHACH_HANG_SAGA,
                payload: { page: 1, pageSize: 30, keyword: '' },
            })
            yield put({
                type: SET_KHACH_HANG_DON_HANG,
                khachHang: data?.data?.newKhachHang
            })
            action.callback(true);
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Thêm Khách Hàng Thành Công!")
        }
    } catch (error) {
        action.callback(false);

        Notification(NOTIFICATION_TYPE.error, "Thêm Khách Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiThemKhachHangSaga() {
    yield takeLatest(THEM_KHACH_HANG_SAGA, themKhachHangSaga)
}

function* chinhSuaThongTinhKhachHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log(action);
        const { khachHangUpdate } = action;
        const { data, status } = yield call(() => {
            return khachHangService.chinhSuaKhachHangAPI(khachHangUpdate?.Id, khachHangUpdate);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_KHACH_HANG_SAGA,
                payload: { page: 1, pageSize: 30, keyword: '' },
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Thông Tin Khách Hàng Thành Công!")
        }


    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Khách Hàng Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaThongTinKhachHangSaga() {
    yield takeLatest(CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA, chinhSuaThongTinhKhachHangSaga);
}

function* xoaKhachHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log(action);
        const { data, status } = yield call(() => {
            return khachHangService.xoaKhachHangAPI(action?.id);
        })
        // console.log(data);
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_KHACH_HANG_SAGA,
                payload: { page: 1, pageSize: 30, keyword: '' },
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa Khách Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa Khách Hàng Khách Hàng Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiXoaKhachHangSaga() {
    yield takeLatest(XOA_KHACH_HANG_SAGA, xoaKhachHangSaga);
}