import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { SET_NGUON_HANG } from '../types/NguonHangType';
import { Notification } from '../../utils/Notifications/Notification';
import { CHINH_SUA_DON_HANG_SAGA, LAY_DANH_SACH_DON_HANG_SAGA, LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA, LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA, SET_DANH_SACH_TINH_TRANG_DON_HANG, SET_DON_HANG, THEM_DON_HANG_SAGA, XOA_DON_HANG_SAGA } from '../types/DonHangType';
import { donHangService } from '../../services/donHangService';
import { history } from '../../utils/constants/History';



function* layDanhSachNguonHang() {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donHangService.layDanhSachNguonHangAPI();
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_NGUON_HANG,
                danhSachNguonHang: data?.data?.danhSachNguonHang
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Nguồn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachNguonHang() {
    yield takeLatest(LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA, layDanhSachNguonHang)
}

function* layDanhSachDonHangSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donHangService.layDanhSachDonHangPhanTrangAPI(page, pageSize, filters?.customerName, filters?.customerPhone, filters?.orderStatus, filters?.idNguonHang);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DON_HANG,
                danhSachDonHang: data?.data?.data,
                totalCount: data?.data?.totalCount,
                page: data?.data?.page,
                pageSize: data?.data?.pageSize
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonHangSaga() {
    yield takeLatest(LAY_DANH_SACH_DON_HANG_SAGA, layDanhSachDonHangSaga)
}

function* themDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log("saga", action);
        const { data, status } = yield call(() => {
            return donHangService.themDonHangAPI(action?.newDonHang);
        });

        // console.log(data);

        if (status == STATUS_CODE.CREATED) {
            Notification(NOTIFICATION_TYPE.success, "Thêm Đơn Hàng Thành Công!")
            action.callback(true);
        }
    } catch (error) {
        action.callback(false);
        Notification(NOTIFICATION_TYPE.error, "Thêm Đơn Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiThemDonHangSaga() {
    yield takeLatest(THEM_DON_HANG_SAGA, themDonHangSaga)
}

function* xoaDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return donHangService.xoaDonHangAPI(action?.id);
        })
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_DON_HANG_SAGA,
                page: 1,
                pageSize: 30
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa Đơn Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa Đơn Hàng Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiXoaDonHangSaga() {
    yield takeLatest(XOA_DON_HANG_SAGA, xoaDonHangSaga);
}

function* chinhSuaDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return donHangService.chinhSuaDonHangAPI(action?.id, action?.editDonHang);
        });


        if (status == STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Đơn Hàng Thành Công!")
            history.push('/don-hang')
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Đơn Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaDonHangSaga() {
    yield takeLatest(CHINH_SUA_DON_HANG_SAGA, chinhSuaDonHangSaga)
}

function* layDanhSachTinhTrangDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donHangService.layDanhSachTinhTrangDonHangAPI();
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_TINH_TRANG_DON_HANG,
                danhSachTinhTrangDonHang: data?.data?.danhSachTinhTrangDonHang
            })
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Tình Trạng Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachTinhTrangDonHangSaga() {
    yield takeLatest(LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA, layDanhSachTinhTrangDonHangSaga)
}