import { Input, Button } from "antd";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  PhoneOutlined,
  BankOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../../redux/types/GlobalType";
import {
  CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA,
  THEM_KHACH_HANG_SAGA,
} from "../../../redux/types/KhachHangType";
import { type } from "@testing-library/user-event/dist/type";
import { SET_KHACH_HANG_DON_HANG } from "../../../redux/types/DonHangType";

function FormThemKhachHangDonHang(props) {
  const dispatch = useDispatch();

  const { khachHang } = useSelector((state) => state.DonHangReducer.khachHang);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="container">
      <h6 className="ms-1 mt-3">Tên Khách Hàng</h6>
      <div className="d-flex">
        <Input
          name="tenKhachHang"
          value={values.tenKhachHang}
          size="large"
          placeholder="Tên Khách Hàng"
          prefix={<UserOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.tenKhachHang}</div>

      <h6 className="mt-3 ml-1">Số Điện Thoại</h6>
      <div className="d-flex">
        <Input
          value={values.soDienThoai}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="soDienThoai"
          size="large"
          placeholder="Số điện thoại"
          prefix={<PhoneOutlined />}
        />
      </div>
      <div className="text-danger">{errors.soDienThoai}</div>

      <h6 className="mt-3 ml-1">Địa Chỉ Nhận Hàng</h6>
      <div className="d-flex">
        <Input
          value={values.diaChiNhanHang}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="diaChiNhanHang"
          size="large"
          placeholder="Địa Chỉ Nhận Hàng"
          prefix={<BankOutlined />}
        />
      </div>
      <div className="text-danger">{errors.diaChiNhanHang}</div>

      <h6 className="mt-3 ml-1">Link Facebook</h6>
      <div className="d-flex">
        <Input
          onChange={handleChange}
          value={values.linkFaceBook}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="linkFaceBook"
          size="large"
          placeholder="Link Facebook"
          prefix={<FacebookOutlined />}
        />
      </div>
      <div className="text-danger">{errors.linkFaceBook}</div>
    </form>
  );
}

const FormThemKhachHangDonHangFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return {
      tenKhachHang: props?.khachHang?.TenKhachHang,
      soDienThoai: props?.khachHang?.SoDienThoai,
      diaChiNhanHang: props?.khachHang?.DiaChiNhanHang,
      linkFaceBook: props?.khachHang?.LinkFacebook,
    };
  },
  validationSchema: Yup.object().shape({
    tenKhachHang: Yup.string().required("Tên Khách Hàng bắt buộc"),
    diaChiNhanHang: Yup.string().required("Địa Chỉ Nhận Hàng bắt buộc"),
    soDienThoai: Yup.number()
      .typeError("Số điện thoại không đúng định dạng")
      .min(10, "số điện thoại ít nhất 10 chữ số")
      .required("Số điện thoại bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.dispatch({
      type: THEM_KHACH_HANG_SAGA,
      newKhachHang: { ...values },
      callback: (success) => {
        if (success) {
          // props.dispatch({
          //   type: SET_KHACH_HANG_DON_HANG,
          //   khachHang: { ...values },
          // });
          resetForm();
        }
      },
    });
  },
})(FormThemKhachHangDonHang);

const mapStateToProp = (state) => {
  return {
    khachHang: state.DonHangReducer?.khachHang,
  };
};

export default connect(mapStateToProp)(FormThemKhachHangDonHangFormik);
