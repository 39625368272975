import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachTaiKhoanAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/quan_tri/lay_danh_sach_tai_khoan`);
}

const themNguoiDungAPI = async (newUser) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/quan_tri/them_moi_tai_khoan`, { ...newUser })
}

const chinhSuaNguoiDungAPI = async (id, userUpdate) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/quan_tri/chinh_sua_thong_tin_nguoi_dung/${id}`, { ...userUpdate });
}

const thayDoiTrangThaiAPI = async (id) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/quan_tri/thay_doi_trang_thai/${id}`);
}

const layDanhSachNguoiDungKeywordAPI = async (keyword) => {
    // console.log(keyword);
    return await authorizedAxiosInstance.post(`${DOMAIN}/quan_tri/lay_danh_sach_tai_khoan_by_keyword`, { "keyword": keyword })
}

export const quanTriService = {
    layDanhSachTaiKhoanAPI,
    themNguoiDungAPI,
    chinhSuaNguoiDungAPI,
    thayDoiTrangThaiAPI,
    layDanhSachNguoiDungKeywordAPI,
}