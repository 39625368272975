import React from 'react'
import { useDispatch } from 'react-redux'
import { OPEN_DRAWER } from '../../redux/types/GlobalType';

export default function Home(props) {
    const dispatch = useDispatch();
    return (
        <div>
            {/* <button className='btn btn-dark' onClick={
                () => {
                    dispatch({ type: OPEN_DRAWER })
                }
            }>open</button> */}
        </div>
    )
}
