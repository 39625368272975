import { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_INFO,
} from "../utils/settings/config";

export const LoginRoute = (props) => {
  const userInfo = localStorage.getItem(USER_INFO);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const { Component, ...restProps } = props;
  return (
    <Route
      {...restProps}
      render={(propsRoute) => {
        if (!userInfo || !accessToken || !refreshToken) {
          return (
            <Fragment>
              <Component {...propsRoute} />
            </Fragment>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};
