import { all } from "redux-saga/effects";
import * as LoginSaga from './LoginSaga'
import * as QuanTriSaga from './QuanTriSaga'
import * as VaiTroSaga from "./VaiTroSaga"
import * as KhachHangSaga from './KhachHangSaga'
import * as TienTeSaga from './TienTeSaga'
import * as NguonHangSaga from './NguonHangSaga'
import * as DonHangSaga from './DonHangSaga'
import * as OrderSaga from './OrderSaga'
import * as NhanDonSaga from './NhanDonSaga'
import * as DonShipSaga from './DonShipSaga'
import * as KetThucSaga from './KetThucSaga'
import * as ChiPhiSaga from './ChiPhiSaga'

export function* rootSaga() {
    yield all([
        LoginSaga.theoDoiSigninSaga(),
        LoginSaga.theoDoiLogoutSaga(),

        // quan tri saga

        QuanTriSaga.theoDoiLayDanhSachNguoiDungSaga(),
        QuanTriSaga.theoDoiThemMoiNguoiDungSaga(),
        QuanTriSaga.theoDoiChinhSuaNguoiDungSaga(),
        QuanTriSaga.theoDoiThayDoiTrangThaiNguoiDungSaga(),
        QuanTriSaga.theoDoiLayDanhSachNguoiDungByKeywordSaga(),

        // vai tro saga

        VaiTroSaga.theoDoiLayDanhSachVaiTroSaga(),
        VaiTroSaga.theoDoiThayDoiVaiTroSaga(),

        // khach hang saga

        KhachHangSaga.theoDoiLayDanhSachKhachHangSaga(),
        KhachHangSaga.theoDoiLayDanhSachKhachHangTheoKeywordSaga(),
        KhachHangSaga.theoDoiThemKhachHangSaga(),
        KhachHangSaga.theoDoiChinhSuaThongTinKhachHangSaga(),
        KhachHangSaga.theoDoiXoaKhachHangSaga(),

        // tien te saga

        TienTeSaga.theoDoiLayDanhSachTienTeSaga(),
        TienTeSaga.theoDoiChinhSuaTiGiaTienTeSaga(),


        // nguon hang

        NguonHangSaga.theoDoiLayDanhSachNguonHang(),
        NguonHangSaga.theoDoiTimKiemNguonHangSaga(),
        NguonHangSaga.theoDoiThemNguonHangSaga(),
        NguonHangSaga.theoDoiXoasNguonHangSaga(),
        NguonHangSaga.theoDoiChinhSuaNguonHangSaga(),


        // Don hang

        DonHangSaga.theoDoiLayDanhSachNguonHang(),
        DonHangSaga.theoDoiLayDanhSachDonHangSaga(),
        DonHangSaga.theoDoiThemDonHangSaga(),
        DonHangSaga.theoDoiXoaDonHangSaga(),
        DonHangSaga.theoDoiChinhSuaDonHangSaga(),
        DonHangSaga.theoDoiLayDanhSachTinhTrangDonHangSaga(),

        // OrderSaga

        OrderSaga.theoDoiLayDanhSachDonHangSaga(),
        OrderSaga.theoDoiTaoOrderSaga(),
        OrderSaga.theoDoiLayDanhOrderSaga(),
        OrderSaga.theoXoaOrderSaga(),
        OrderSaga.theoDoiLayChiTietOrderSaga(),
        OrderSaga.theoXoaThayDoiDonHangOrderSaga(),
        OrderSaga.theoDoiChinhSuaOrderSaga(),

        // Nguon Hang

        NhanDonSaga.theoDoiLayDanhSachNguonHangTrangNhanDonSaga(),
        NhanDonSaga.theoDoiLayDanhOrderTrangNhanDonSaga(),
        NhanDonSaga.theoDoiLayDanhSachDonHangOrderNhanDonSaga(),
        NhanDonSaga.theoDoiChinhSuaDonHangOrderNhanHangSaga(),
        NhanDonSaga.theoDoiXacNhanDaNhanHangSaga(),


        // Don Ship 

        DonShipSaga.theoDoiLayDanhSachDonShipSaga(),
        DonShipSaga.theoDoiLayDanhSachDonHangSaga(),
        DonShipSaga.theoLayDanhSachDonViVanChuyenSaga(),
        DonShipSaga.theoThemDonShipSaga(),
        DonShipSaga.theoXoaDonShipSaga(),
        DonShipSaga.theoDoiLayChiTietDonShipSaga(),
        DonShipSaga.theoXoaThayDoiDonHangTrangShipSaga(),
        DonShipSaga.theoDoiChinhSuaDonShipSaga(),
        DonShipSaga.theoDoiChinhSuaTrangThaiDonShipSaga(),
        DonShipSaga.theoDoiLayChiTietDonShipSagaV2(),
        DonShipSaga.theoLayDanhSachTinhTrangDonShipSaga(),
        DonShipSaga.theoTaoDonShipChuaShipSaga(),


        // ket thuc

        KetThucSaga.theoDoiLayDanhSachDonHangTrangKetThucSaga(),
        KetThucSaga.theoDoiChinhSuaTrangThaiDonHangSaga(),

        // Chi Phi

        ChiPhiSaga.theoDoiLayDanhSachNhomChiPhiSaga(),
        ChiPhiSaga.theoDoiLayDanhChiPhiSaga(),
        ChiPhiSaga.theoDoiXoaCHiPhiSaga(),
        ChiPhiSaga.theoDoiThemChiPhiSaga(),
        ChiPhiSaga.theoDoiChinhSuaChiPhiSaga(),
    ])
}