import { SET_DANH_SACH_TINH_TRANG_DON_HANG, SET_DON_HANG, SET_EDIT_DON_HANG } from "../types/DonHangType";
import { SET_DANH_SACH_DON_HANG_TRANG_KET_THUC } from "../types/KetThucType";

const initialState = {
    danhSachDonHang: [
    ],
    totalCount: 0,
    danhSachTinhTrangDonHang: [
        {
            "Id": 1,
            "Ten": "Mới lên đơn"
        },
        {
            "Id": 2,
            "Ten": "Đã order thành công"
        },
        {
            "Id": 3,
            "Ten": "Đợi mua lại"
        },
        {
            "Id": 4,
            "Ten": "Không mua được"
        },
        {
            "Id": 5,
            "Ten": "Hàng đã về, đợi ship"
        },
        {
            "Id": 6,
            "Ten": "Đã ship đi"
        },
        {
            "Id": 7,
            "Ten": "Đã hoàn thành"
        },
        {
            "Id": 8,
            "Ten": "Trả hàng"
        },
        {
            "Id": 9,
            "Ten": "Nguồn hàng ship thiếu"
        },
        {
            "Id": 10,
            "Ten": "Lưu kho đợi thêm đồ/hẹn ngày ship"
        }
    ]
}

export const KetThucReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_DON_HANG_TRANG_KET_THUC: {
            state.danhSachDonHang = action.danhSachDonHang;
            state.totalCount = action?.totalCount;
            return { ...state }
        }

        default:
            return state
    }
}
