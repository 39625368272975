export const DOMAIN = "https://api.megau.com.vn/api";
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const USER_INFO = 'USER_INFO'

export const NOTIFICATION_TYPE = {
    success: 'success',
    warning: 'warning',
    info: 'info',
    error: 'error'
}

export const STATUS_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    NOT_FOUND: 404,
    SERVER_ERROR: 500
}