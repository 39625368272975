import { SET_DANH_SACH_TIEN_TE, SET_EDIT_TIEN_TE } from "../types/TienTeType";

const initialState = {
    danhSachTienTe: [
    ],
    tienTeEdit: {
        "Id": 3,
        "TienTe": "VND",
        "TiGia": "1000"
    }
}

export const TienTeReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_TIEN_TE: {
            state.danhSachTienTe = action.danhSachTienTe;
            return { ...state }
        }

        case SET_EDIT_TIEN_TE: {
            state.tienTeEdit = action?.tienTeEdit
            return { ...state }
        }

        default:
            return state
    }
}
