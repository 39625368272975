import { call, put, takeLatest, delay } from "redux-saga/effects";
import { CHINH_SUA_NGUOI_DUNG_SAGA, LAY_DANH_SACH_KEYWORD_SAGA, LAY_DANH_SACH_NGUOI_DUNG, LAY_DANH_SACH_NGUOI_DUNG_SAGA, THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA, THEM_MOI_NGUOI_DUNG_SAGA } from "../types/QuanTriType";
import { DISPLAY_LOADING, HIDE_LOADING } from "../types/LoadingType";
import { quanTriService } from "../../services/quanTriService";
import { NOTIFICATION_TYPE, STATUS_CODE } from "../../utils/settings/config";
import { CLEAN_CONTENT_DRAWER, CLOSE_DRAWER } from "../types/GlobalType";
import { Notification } from "../../utils/Notifications/Notification";

function* layDanhSachNguoiDungSaga() {
    yield put({
        type: DISPLAY_LOADING,
    })
    try {
        const { data, status } = yield call(() => {
            return quanTriService.layDanhSachTaiKhoanAPI();
        });

        // console.log("======", data);
        if (status === STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG,
                danhSachNguoiDung: data?.data,
            });
        }
    } catch (err) {
        Notification(NOTIFICATION_TYPE.error, "lấy Danh Sách Tài Khoản thất bại", err?.response?.data?.message)

    }
    yield put({
        type: HIDE_LOADING,
    })
}

export function* theoDoiLayDanhSachNguoiDungSaga() {
    yield takeLatest(LAY_DANH_SACH_NGUOI_DUNG_SAGA, layDanhSachNguoiDungSaga);
}

function* themMoiNguoiDungSaga(action) {
    yield put({
        type: DISPLAY_LOADING,
    })
    try {
        // console.log("saga", action);
        const { data, status } = yield call(() => {
            return quanTriService.themNguoiDungAPI(action.newUser);
        });
        // console.log(data);
        if (status === STATUS_CODE.CREATED) {
            yield put({
                type: CLEAN_CONTENT_DRAWER,
            });
            Notification(NOTIFICATION_TYPE.success, "Thêm tài khoản thành công")
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG_SAGA,
            });
        }
    } catch (err) {
        // console.log(err);
        Notification(NOTIFICATION_TYPE.error, "Thêm Tài Khoản thất bại", err?.response?.data?.message)
    }
    yield put({
        type: HIDE_LOADING,
    })
}

export function* theoDoiThemMoiNguoiDungSaga() {
    yield takeLatest(THEM_MOI_NGUOI_DUNG_SAGA, themMoiNguoiDungSaga);
}

function* chinhSuaNguoiDungSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { userUpdate } = action;
        const { data, status } = yield call(() => {
            return quanTriService.chinhSuaNguoiDungAPI(userUpdate?.Id, userUpdate);
        });
        // console.log(data);
        if (status === STATUS_CODE.SUCCESS) {
            yield put({
                type: CLEAN_CONTENT_DRAWER,
            });
            Notification(NOTIFICATION_TYPE.success, "Chỉnh sửa tài khoản thành công!")
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG_SAGA,
            });
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh sửa Tài Khoản thất bại", error?.response?.data?.message)
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaNguoiDungSaga() {
    yield takeLatest(CHINH_SUA_NGUOI_DUNG_SAGA, chinhSuaNguoiDungSaga);
}

function* thayDoiTrangThaiNguoiDungSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    });

    try {
        const { dataUser } = action;

        const { data, status } = yield call(() => {
            return quanTriService.thayDoiTrangThaiAPI(dataUser?.Id);
        });

        if (status === STATUS_CODE.SUCCESS) {
            yield put({
                type: CLEAN_CONTENT_DRAWER,
            });
            Notification(NOTIFICATION_TYPE.success, (dataUser?.is_activated) ? `Khóa tài khoản ${dataUser?.Username} thành Công` : `Mở Khóa tài khoản ${dataUser?.Username} thành Công`)
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG_SAGA,
            });
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Thay Đổi Trạng Thái Tài Khoản thất bại", error?.response?.data?.message)
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiThayDoiTrangThaiNguoiDungSaga() {
    yield takeLatest(THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA, thayDoiTrangThaiNguoiDungSaga)
}

function* layDanhSachNguoiDungByKeywordSaga(action) {
    yield put({
        type: DISPLAY_LOADING,
    })
    try {

        const { data, status } = yield call(() => {
            return quanTriService.layDanhSachNguoiDungKeywordAPI(action?.keyword);
        });

        // console.log(data);

        // console.log("======", data);
        if (status === STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG,
                danhSachNguoiDung: data?.data,
            });
        }
    } catch (err) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Danh Sách Tài Khoản thất bại", err?.response?.data?.message)

    }
    yield put({
        type: HIDE_LOADING,
    })
}

export function* theoDoiLayDanhSachNguoiDungByKeywordSaga() {
    yield takeLatest(LAY_DANH_SACH_KEYWORD_SAGA, layDanhSachNguoiDungByKeywordSaga);
}