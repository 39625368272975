import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Popconfirm,
  message,
  Popover,
  AutoComplete,
  Divider,
  Flex,
  Tooltip,
  Input,
} from "antd";
// import ReactHtmlParser from "react-html-parser";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  LAY_DANH_SACH_KEYWORD_SAGA,
  LAY_DANH_SACH_NGUOI_DUNG_SAGA,
  SET_USER_EDIT,
  THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA,
} from "../../redux/types/QuanTriType";
import {
  OPEN_FORM_CREATE_USER,
  SET_CONTENT_DRAWER,
} from "../../redux/types/GlobalType";
import FormCreateUser from "../../components/Forms/FormCreateUser";
import FormEditUser from "../../components/Forms/FormEditUser";
import FormEditRole from "../../components/Forms/FormEditRole";
import { LAY_DANH_SACH_VAI_TRO_SAGA } from "../../redux/types/VaiTroType";
// import FormEditProject from "../../../components/Forms/FormEditProject.js/FormEditProject";

const colors = ["success", "processing", "error", "warning", "default"];

export default function QuanLyNguoiDung(props) {
  const [dataSearch, setDataSearch] = useState("");

  const dispatch = useDispatch();
  const { danhSachNguoiDung } = useSelector((state) => state.QuanTriReducer);

  // console.log(danhSachNguoiDung);

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_NGUOI_DUNG_SAGA });
    dispatch({ type: LAY_DANH_SACH_VAI_TRO_SAGA });
  }, []);
  //Lấy dữ liệu từ reducer về component
  const projectList = [
    {
      Id: 4,
      MaNhanVien: "NV01",
      TenNhanVien: "Duong Xuan Quyet",
      Username: "quyet",
      is_activated: true,
      roles: ["ADMIN"],
    },
    {
      Id: 5,
      MaNhanVien: "NV01",
      TenNhanVien: "Duong Xuan hiep",
      Username: "quyet1",
      is_activated: false,
      roles: ["nhan_vien_kho", "ke_toan"],
    },
  ];

  // const { userSearch } = useSelector(
  //   (state) => state.UserLoginCyberBugsReducer
  // );

  // const [value, setValue] = useState("");

  //Sử dụng useDispatch để gọi action
  // const dispatch = useDispatch();
  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  // useEffect(() => {
  //     dispatch({ type: 'GET_LIST_PROJECT_SAGA' })
  // }, [])

  const handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination, filters, sorter);
    setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearFilters = () => {
    setState({ filteredInfo: null });
  };

  const clearAll = () => {
    setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  const setAgeSort = () => {
    setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};
  const columns = [
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
      sorter: (item2, item1) => {
        return item2.Id - item1.Id;
      },
      sortDirections: ["descend"],
    },
    {
      title: "Mã Nhân Viên",
      dataIndex: "MaNhanVien",
      key: "MaNhanVien",
      sorter: (item2, item1) => {
        let maNhanVien1 = item1.MaNhanVien?.trim().toLowerCase();
        let maNhanVien2 = item2.MaNhanVien?.trim().toLowerCase();
        if (maNhanVien2 < maNhanVien1) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: "Tên Nhân Viên",
      dataIndex: "TenNhanVien",
      key: "TenNhanVien",
      sorter: (item2, item1) => {
        let tenNhanVien1 = item1.TenNhanVien?.trim().toLowerCase();
        let tenNhanVien2 = item2.TenNhanVien?.trim().toLowerCase();
        if (tenNhanVien2 < tenNhanVien1) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "Username",
      key: "Username",
      sorter: (item2, item1) => {
        let Username1 = item1.Username?.trim().toLowerCase();
        let Username2 = item2.Username?.trim().toLowerCase();
        if (Username2 < Username1) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: "Vai Trò",
      key: "roles",
      render: (text, record, index) => {
        return (
          <>
            <Flex gap="1px 0" wrap>
              {record?.roles.map((role, index) => {
                return (
                  <Tag key={index} color={colors[index % 5]}>
                    {role}
                  </Tag>
                );
              })}
              <Button
                onClick={() => {
                  // console.log(record);
                  dispatch({
                    type: SET_CONTENT_DRAWER,
                    title: "Thay Đổi Vai Trò",
                    Component: (
                      <FormEditRole
                        selectedRoles={record?.roleIds}
                        idEdit={record?.Id}
                      />
                    ),
                  });
                }}
                size="small"
                type="primary"
                shape="circle"
              >
                <Tooltip title="Thay đổi vai trò">
                  <i className="fa-solid fa-plus"></i>
                </Tooltip>
              </Button>
            </Flex>
          </>
        );
      },
    },
    {
      title: "Trạng Thái",
      key: "is_activated",
      render: (text, record, index) => {
        if (record?.is_activated) {
          return (
            <>
              <Flex gap="1px 0" wrap>
                <Tag color="processing">active</Tag>
              </Flex>
            </>
          );
        } else {
          return (
            <>
              <Flex gap="1px 0" wrap>
                <Tag color="warning">Lock</Tag>
              </Flex>
            </>
          );
        }
      },
    },

    {
      title: "Chức Năng",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => {
        const renderLock = () => {
          if (record?.is_activated)
            return (
              <>
                <LockOutlined style={{ fontSize: 17, color: "white" }} />
              </>
            );
          else {
            return (
              <>
                <UnlockOutlined style={{ fontSize: 17, color: "white" }} />
              </>
            );
          }
        };

        return (
          <div>
            <Tooltip title="Thay đổi trạng thái">
              <button
                onClick={() => {
                  // console.log(record);
                  dispatch({
                    type: THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA,
                    dataUser: record,
                  });
                }}
                className="btn me-2 btn-dark"
              >
                {renderLock()}
              </button>
            </Tooltip>

            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                // gửi dữ liệu hiện tại lên QuanTriReducer
                const actionSetUsetEdit = {
                  type: SET_USER_EDIT,
                  userEdit: record,
                };
                dispatch(actionSetUsetEdit);

                // set content drawer
                const actionSetContentDrawer = {
                  type: SET_CONTENT_DRAWER,
                  title: "Chỉnh Sửa thông Tin Người Dùng",
                  Component: <FormEditUser />,
                };

                dispatch(actionSetContentDrawer);
              }}
            >
              <Tooltip title="Chỉnh Sửa Người Dùng">
                <FormOutlined style={{ fontSize: 17 }} />
              </Tooltip>
            </button>
          </div>
        );
      },
    },
  ];

  const renderSearch = () => {
    return (
      <div className="my-4 mt-3 ms-md-4 row">
        <Input
          onChange={(e) => {
            setDataSearch(e.target.value);
          }}
          className="col-md-9"
          placeholder="Tìm kiếm theo tên nhân viên ...."
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={() => {
            dispatch({
              type: LAY_DANH_SACH_KEYWORD_SAGA,
              keyword: dataSearch,
            });
          }}
          className="col-md-2 ml-3"
          type="primary"
        >
          Tìm Kiếm
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Quản Lý Người Dùng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            dispatch({
              type: OPEN_FORM_CREATE_USER,
              title: "Thêm người dùng mới",
              Component: <FormCreateUser />,
            });
          }}
        >
          <UserAddOutlined style={{ fontSize: 18 }} /> Thêm Mới Người Dùng
        </button>
      </div>

      <div className="ms-3">{renderSearch()}</div>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={danhSachNguoiDung}
        onChange={handleChange}
      />
    </div>
  );
}
