// src/HOC/ProtectedRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserInfoFromLocalStorage } from "../utils/getInfoUser/getInfoUser";

export const ProtectedRoute = ({
  component: Component,
  requiredPermission,
  ...rest
}) => {
  const userInfo = getUserInfoFromLocalStorage();

  const hasPermission = () => {
    if (!userInfo || !userInfo.permission) return false;
    const combinedPermissions = combinePermissions(userInfo.permission);
    return combinedPermissions[requiredPermission]?.read;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const combinePermissions = (roles) => {
  const combinedPermissions = {};
  roles.forEach((role) => {
    for (const permission in role) {
      if (!combinedPermissions[permission]) {
        combinedPermissions[permission] = { ...role[permission] };
      } else {
        for (const action in role[permission]) {
          combinedPermissions[permission][action] =
            combinedPermissions[permission][action] || role[permission][action];
        }
      }
    }
  });
  return combinedPermissions;
};
