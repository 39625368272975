import React from 'react'
import { useSelector } from 'react-redux'
import stylingLoading from './Loading.module.css'

export default function LoadingComponent() {

    let { isLoading } = useSelector(state => state.LoadingReducer);

    if (isLoading) {
        return <div className={stylingLoading.bgLoading}>
            <img src={require('../../assets/imageLoading/loading.gif')} alt='loading' />
        </div>
    }else{
        return ""
    }
}
