import { DOMAIN, REFRESH_TOKEN, USER_INFO } from "../utils/settings/config";
import authorizedAxiosInstance from "./authorizedAxios";

const refreshTokenAPI = async (refreshToken) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/auth/refresh_token`, { refreshToken })
}

const loginAPI = async (userLogin) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/auth/login`, { ...userLogin });
}

const logoutAPI = async () => {
    localStorage.removeItem(USER_INFO);
    localStorage.removeItem(REFRESH_TOKEN);
    return await authorizedAxiosInstance.delete(`${DOMAIN}/auth/logout`);
}



export const authService = {
    refreshTokenAPI,
    loginAPI,
    logoutAPI
}