import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Tooltip,
} from "antd";

import { FormOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Lay_DANH_SACH_NGUON_HANG_SAGA } from "../../redux/types/NguonHangType";
import {
  LAY_DANH_SACH_ORDER_SAGA,
  XOA_ORDER_SAGA,
} from "../../redux/types/OderType";
import { history } from "../../utils/constants/History";

const { RangePicker } = DatePicker;

export default function DanhSachOrder(props) {
  const dispatch = useDispatch();

  const { totalCountOrder, danhSachTinhTrangOrder, danhSachOrder } =
    useSelector((state) => state.OrderReducer);

  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);

  const [filters, setFilters] = useState({
    label: "",
    orderStatus: "",
    startDate: "",
    endDate: "",
  });

  const handleResetSearch = () => {
    setFilters({
      label: "",
      orderStatus: "",
      startDate: "",
      endDate: "",
    });
    handleSearch();
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  useEffect(() => {
    dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA });
  }, [dispatch]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset to first page on new search
    setPagination(newPagination); // Update the pagination state

    dispatch({
      type: LAY_DANH_SACH_ORDER_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_ORDER_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Order");
  };

  const columns = [
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
    },
    {
      title: "Mã Order",
      dataIndex: "MaOrder",
      key: "MaOrder",
    },
    {
      title: "Tracking number",
      dataIndex: "TrackingNumber",
      key: "TrackingNumber",
    },
    {
      title: "Số Đơn",
      dataIndex: "SoDon",
      key: "SoDon",
    },
    {
      title: "Số Sản Phẩm",
      dataIndex: "SoSanPham",
      key: "SoSanPham",
    },
    {
      title: "Số Tiền",
      dataIndex: "SoTien",
      key: "SoTien",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "ShipWeb",
      dataIndex: "ShipWeb",
      key: "ShipWeb",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "Ngày Nhận Hàng DK",
      dataIndex: "NgayNhanDonHangDuKien",
      key: "NgayNhanDonHangDuKien",
      render: (text) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Ngày Nhận Hàng",
      dataIndex: "NgayNhanHang",
      key: "NgayNhanHang",
      render: (text) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Tình Trạng Order",
      dataIndex: "TenTinhTrangOrder",
      key: "TenTinhTrangOrder",
    },
    {
      title: "Thông tin order",
      dataIndex: "Ngay",
      key: "Ngay",
      render: (text, record) => {
        return (
          <>
            <div>
              Ngày tạo:{" "}
              <span className="fw-bold">
                {dayjs(text).format("DD/MM/YYYY")}
              </span>
            </div>
            <div>
              Người tạo:{" "}
              <span className="fw-bold">{record?.TenNhanVienOrder}</span>
            </div>
            <div>
              mã NV:<span className="fw-bold">{record?.MaNhanVienOrder}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Chức Năng",
      key: "Select",
      render: (text, record) => {
        return (
          <>
            <div>
              <button
                className="btn me-2 btn-primary"
                onClick={() => {
                  history.push(`/order/chinh-sua-order/${record?.Id}`);
                }}
              >
                <Tooltip title="Chỉnh sửa order">
                  <FormOutlined style={{ fontSize: 17 }} />
                </Tooltip>
              </button>
              <Popconfirm
                title="Xóa Order"
                description={`Bạn có chắc chắn muốn xóa order "${record?.Id}"?`}
                onConfirm={() => {
                  dispatch({
                    type: XOA_ORDER_SAGA,
                    id: record?.Id,
                  });
                }}
                onCancel={cancel}
                okText="Có"
                cancelText="Không"
              >
                <button className="btn btn-danger ml-2">
                  <Tooltip title="Xóa order">
                    <i className="fa-solid fa-trash"></i>
                  </Tooltip>
                </button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Danh Sách Order</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/order/tao_moi_order");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Order
        </button>
      </div>
      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Chọn Nguồn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.label}
            onChange={(value) => setFilters({ ...filters, label: value })}
            placeholder="Chọn nguồn hàng"
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNguonHang?.map((item, key) => (
              <Select.Option key={key} value={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 ml-md-2">
          <h6>Tình Trạng Order</h6>
          <Select
            style={{ width: "100%" }}
            placeholder="Tình trạng OrderD"
            value={filters.orderStatus}
            onChange={(value) => setFilters({ ...filters, orderStatus: value })}
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachTinhTrangOrder?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-4 me-md-2">
          <h6>Lọc theo Ngày Tạo</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>
        <div className="col-md-3 row mt-3">
          <Button className="col-md-6" type="primary" onClick={handleSearch}>
            Tìm Kiếm
          </Button>
          <Button
            className="col-md-5 ms-md-2"
            type="default"
            onClick={handleResetSearch}
          >
            Hủy Tìm Kiếm
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachOrder}
        pagination={{
          ...pagination,
          total: totalCountOrder,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
