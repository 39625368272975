import { call, put, takeLatest, delay } from "redux-saga/effects";
import { LAY_DANH_SACH_VAI_TRO_SAGA, THAY_DOI_VAI_TRO_SAGA } from "../types/VaiTroType";
import { DISPLAY_LOADING, HIDE_LOADING } from "../types/LoadingType";
import { NOTIFICATION_TYPE, STATUS_CODE } from "../../utils/settings/config";
import { roleService } from "../../services/roleService";
import { LAY_DANH_SACH_NGUOI_DUNG_SAGA, SET_DANH_SACH_VAI_TRO } from "../types/QuanTriType";
import { CLEAN_CONTENT_DRAWER } from "../types/GlobalType";
import { Notification } from "../../utils/Notifications/Notification";
function* layDanhSachVaiTroSaga() {
    yield put({
        type: DISPLAY_LOADING,
    })
    try {
        const { data, status } = yield call(() => {
            return roleService.layDanhSachVaiTroAPI();
        });

        // console.log(data);
        // console.log("======", data);
        if (status === STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_VAI_TRO,
                danhSachVaiTro: data?.data?.danhSachVaiTro,
            });
        }
    } catch (err) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Danh Sách Vai Trò thất bại", err?.response?.data?.message)

    }
    yield put({
        type: HIDE_LOADING,
    })
}

export function* theoDoiLayDanhSachVaiTroSaga() {
    yield takeLatest(LAY_DANH_SACH_VAI_TRO_SAGA, layDanhSachVaiTroSaga);
}

function* thayDoiVaiTroSaga(action) {
    yield put({
        type: DISPLAY_LOADING,
    })
    try {
        const { id, roles } = action?.values;

        const { data, status } = yield call(() => {
            return roleService.thayDoiVaiTroAPI(id, roles);
        })
        // console.log(data);
        if (status === STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Thay đổi vai trò thành công")
            yield put({
                type: LAY_DANH_SACH_NGUOI_DUNG_SAGA,
            });
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Thay Đổi Vai Trò  Tài Khoản thất bại", error?.response?.data?.message)
    }
    yield put({
        type: CLEAN_CONTENT_DRAWER,
    });

    yield put({
        type: HIDE_LOADING,
    })
}

export function* theoDoiThayDoiVaiTroSaga() {
    yield takeLatest(THAY_DOI_VAI_TRO_SAGA, thayDoiVaiTroSaga)
}