import React, { useEffect } from "react";
import { Table, Button, Popconfirm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  LAY_DANH_SACH_CHON_NHAN_DON_SAGA,
  SET_DON_HANG_EDIT_NHAN_DON,
  XAC_NHAN_DA_NHAN_HANG_SAGA,
} from "../../redux/types/NhanDonType";
import { SET_CONTENT_DRAWER } from "../../redux/types/GlobalType";
import FormChinhSuaDonHangNhanDon from "../../components/Forms/NhanDon/FormChinhSuaDonHangNhanDon";

export default function ChinhSuaOrderNhanDon(props) {
  const dispatch = useDispatch();

  const { danhSachDonHangDaChon, orderEdit } = useSelector(
    (state) => state.NhanDonReducer
  );

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_CHON_NHAN_DON_SAGA,
      id: props?.match?.params?.id,
    });
  }, []);

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const selectedOrderColumns = [
    {
      title: "Mã Đơn Hàng",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
    },
    {
      title: "Ngày Lên Đơn",
      dataIndex: "NgayTao",
      key: "NgayTao",
      render: (text) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "125px",
                height: "125px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Thông Tin Sản phẩm",
      key: "LinkSanPham",
      width: 200,
      render: (text, record) => (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "10px" }}>
              <div>
                Tên SP:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.TenSanPham}
                </span>
              </div>
              <div>
                Màu:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.MauSanPham}
                </span>
              </div>
              <div>
                Size:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.Size}
                </span>
              </div>
              <div>
                Cân Nặng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
              </div>
              <div>
                Số Lượng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>
              <div>
                Giá Web:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>
              </div>
              <div>
                CodeOff:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.CodeOff}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thông Tin Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div className="row">
              Giá Báo Khách:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div className="row">
              Tiền Cọc:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div className="row">
              Tổng Tiền Cân:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Tình Trạng Đơn Hàng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            {record?.IdTinhTrang === 3 ||
            record?.IdTinhTrang === 4 ||
            record?.IdTinhTrang === 8 ||
            record?.IdTinhTrang === 9 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },
    {
      title: "Hành Động",
      key: "action",
      render: (text, record) => {
        return (
          <div>
            <button
              className="btn btn-primary me-2"
              type="link"
              onClick={() => {
                dispatch({
                  type: SET_DON_HANG_EDIT_NHAN_DON,
                  record: {
                    ...record,
                    idOrder: orderEdit?.Id,
                  },
                });

                dispatch({
                  type: SET_CONTENT_DRAWER,
                  Component: <FormChinhSuaDonHangNhanDon />,
                  title: "Chỉnh Sửa Trạng Thái Đơn Hàng",
                });
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i> Chỉnh Sửa
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h3 className="col-md-9">Nhận Đơn</h3>

      <h4>Danh Sách Đơn Hàng</h4>

      <Table
        columns={selectedOrderColumns}
        rowKey={"Id"}
        dataSource={danhSachDonHangDaChon}
        pagination={false} // Disable pagination for selected orders table
      />

      {orderEdit?.IdTinhTrang != 2 ? (
        <Button
          htmlType="submit"
          size="large"
          className="mt-3"
          style={{
            width: "100%",
            backgroundColor: "#1890ff",
            color: "white",
          }}
        >
          <Popconfirm
            title="Bạn có chắc chắn đã nhận hàng không?"
            onConfirm={() => {
              dispatch({
                type: XAC_NHAN_DA_NHAN_HANG_SAGA,
                idOrder: orderEdit?.Id,
              });
            }}
            okText="Xác nhận"
            cancelText="Hủy"
          >
            <span>Xác nhận đã Nhận Hàng</span>
          </Popconfirm>
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
