import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Popconfirm,
  message,
  Popover,
  AutoComplete,
  Divider,
  Flex,
  Tooltip,
  Input,
  Checkbox,
  Select,
} from "antd";
import moment from "moment";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  LAY_DANH_SACH_DON_HANG_SAGA,
  LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA,
  LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA,
  SET_EDIT_DON_HANG,
  XOA_DON_HANG_SAGA,
} from "../../redux/types/DonHangType";
import { history } from "../../utils/constants/History";
import { donHangService } from "../../services/donHangService";
import { STATUS_CODE } from "../../utils/settings/config";
const { Option } = Select;
export default function QuanLyDonHang(props) {
  const [dataSearch, setDataSearch] = useState("");

  const dispatch = useDispatch();
  const { danhSachDonHang, totalCount, danhSachTinhTrangDonHang, danh } =
    useSelector((state) => state.DonHangReducer);

  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);

  const [filters, setFilters] = useState({
    customerName: "",
    customerPhone: "",
    orderStatus: "",
    idNguonHang: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA });
    dispatch({ type: LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA });
    // Fetch initial list of orders
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, []); // Run only on mount

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset to first page on new search
    setPagination(newPagination); // Update the pagination state

    // Dispatch the action with new pagination and filters
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_SAGA,
      page: newPagination.current,
      pageSize: newPagination.pageSize,
      filters,
    });
  };

  const handleResetSearch = () => {
    setFilters({
      customerName: "",
      customerPhone: "",
      orderStatus: "",
      idNguonHang: "",
    });
    handleSearch();
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Đơn Hàng");
  };

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = [
    {
      title: "Mã Đơn Hàng",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "125px",
                height: "125px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Thông Tin Sản phẩm",
      key: "LinkSanPham",
      width: 200,
      render: (text, record) => (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "10px" }}>
              <div>
                Tên SP:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.TenSanPham}
                </span>
              </div>
              <div>
                Màu:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.MauSanPham}
                </span>
              </div>
              <div>
                Size:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.Size}
                </span>
              </div>
              <div>
                Cân Nặng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
              </div>
              <div>
                Số Lượng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>
              <div>
                Giá Web:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>
              </div>
              <div>
                CodeOff:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.CodeOff}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thông Tin Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div className="row">
              Hình Thức Thanh Toán:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {record?.TenHinhThucThanhToan}
                </span>
              </span>
            </div>
            <div className="row">
              Giá Báo Khách:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div className="row">
              Tiền Cọc:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div className="row">
              Tổng Tiền Cân:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Thông Tin Về thời gian",
      dataIndex: "NgayHenTraSanPham",
      key: "NgayHenTraSanPham",
      width: 200,
      render: (text, record) => {
        const ngayTao = record?.NgayTao
          ? moment(record?.NgayTao).format("DD/MM/YYYY")
          : "";
        const ngayHenTraSanPham = record?.NgayHenTraSanPham
          ? moment(record?.NgayHenTraSanPham).format("DD/MM/YYYY")
          : "";

        return (
          <>
            <div>
              Ngày Tạo:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayTao}
              </span>
            </div>
            <div>
              Ngày Hẹn Trả SP:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayHenTraSanPham}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Thông Tin Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            Tên KH:{" "}
            <a target="_blank" href={record?.LinkFacebook}>
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.TenKhachHang}
              </span>
            </a>
          </div>
          <div>
            SĐT:{" "}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.SoDienThoai}
            </span>
          </div>

          <span>
            Địa Chỉ Nhận Hàng:
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.DiaChiNhanHang}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: "Ghi Chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Tình Trạng Đơn Hàng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            {record?.IdTinhTrang === 3 ||
            record?.IdTinhTrang === 4 ||
            record?.IdTinhTrang === 8 ||
            record?.IdTinhTrang === 9 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },
    {
      title: "Thông Tin Tạo",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            Tên NV:{" "}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.tbUser?.TenNhanVien}
            </span>
          </div>
          <div>
            Mã NV:{" "}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.tbUser?.MaNhanVien}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Chức Năng",
      key: "x",
      render: (text, record) => (
        <div>
          <div>
            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                dispatch({
                  type: SET_EDIT_DON_HANG,
                  donHangEdit: record,
                });
                history.push("/don-hang/chinh-sua-don-hang");
              }}
            >
              <Tooltip title="Chỉnh sửa đơn hàng">
                <FormOutlined style={{ fontSize: 17 }} />
              </Tooltip>
            </button>
            <Popconfirm
              title="Xóa Đơn Hàng"
              description={`Bạn có chắc chắn muốn xóa Đơn Hàng "${record?.MaDonHang}"?`}
              onConfirm={() => {
                dispatch({
                  type: XOA_DON_HANG_SAGA,
                  id: record?.Id,
                });
              }}
              onCancel={cancel}
              okText="Có"
              cancelText="Không"
            >
              <button className="btn btn-danger ml-2">
                <Tooltip title="Xóa Đơn Hàng">
                  <i className="fa-solid fa-trash"></i>
                </Tooltip>
              </button>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Quản Lý Đơn Hàng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/don-hang/them-moi-don-hang");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Đơn Hàng
        </button>
      </div>

      <div className="search-filters my-4 mt-3 ms-md-4 row">
        <Input
          className="col-md-2"
          placeholder="Tìm kiếm theo tên khách hàng"
          value={filters.customerName}
          onChange={(e) =>
            setFilters({ ...filters, customerName: e.target.value })
          }
          prefix={<SearchOutlined />}
        />
        <Input
          className="col-md-2 ms-md-2"
          placeholder="Tìm kiếm theo SĐT khách hàng"
          value={filters.customerPhone}
          onChange={(e) =>
            setFilters({ ...filters, customerPhone: e.target.value })
          }
          prefix={<SearchOutlined />}
        />
        <div className="col-md-2 ml-md-2">
          <span>Chọn Tình Trạng Đơn Hàng</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Tình trạng đơn hàng"
            value={filters.orderStatus}
            onChange={(value) => setFilters({ ...filters, orderStatus: value })}
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachTinhTrangDonHang?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-2 ml-md-2">
          <span>Chọn Nguồn Hàng</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Chọn Nguồn Hàng"
            value={filters.idNguonHang}
            onChange={(value) => setFilters({ ...filters, idNguonHang: value })}
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNguonHang?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-3 row mt-3">
          <Button className="col-md-2" type="primary" onClick={handleSearch}>
            <i className="fa fa-search"></i>
          </Button>
          <Button
            className="col-md-8 ms-md-2"
            type="default"
            onClick={handleResetSearch}
          >
            Hủy Tìm Kiếm
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={danhSachDonHang}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
