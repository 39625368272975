import authorizedAxiosInstance from "./authorizedAxios";
import { DOMAIN } from "../utils/settings/config";

const uploadImageAPI = async (formData) => {
    try {
        const response = await authorizedAxiosInstance.post(`${DOMAIN}/file/them_anh`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error("Upload failed:", error);
        throw error;
    }
};

export const fileService = {
    uploadImageAPI,
};
