import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  Tooltip,
} from "antd";
import { debounce } from "lodash";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { history } from "../../utils/constants/History";
import {
  CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA,
  LAY_CHI_TIET_DON_SHIP_SAGA,
  LAY_CHI_TIET_DON_SHIP_SAGA_V2,
  LAY_DANH_SACH_DON_SHIP_SAGA,
  LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA,
  XOA_DON_SHIP_SAGA,
} from "../../redux/types/DonShipType";
import ShippingOrderPrint from "../../components/ShippingOrderPrint";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function DanhSachDonShip(props) {
  const dispatch = useDispatch();

  const { totalCount, danhSachDonShip, danhSachTinhTrangDonShip } = useSelector(
    (state) => state.DonShipReducer
  );

  const [filters, setFilters] = useState({
    maVanDon: "",
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA });
  }, []);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const handleSearch = () => {
    setPagination({ ...pagination, current: 1 }); // Reset về trang 1 khi tìm kiếm mới
    dispatch({
      type: LAY_DANH_SACH_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Đơn Ship");
  };

  const [selectedOrder, setSelectedOrder] = useState(null);
  const printRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { danhSachDonHangDaChon } = useSelector(
    (state) => state?.DonShipReducer
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (
      isPrinting &&
      selectedOrder &&
      danhSachDonHangDaChon?.length > 0 &&
      isDataLoaded
    ) {
      handlePrint();
      setIsPrinting(false);
    }
  }, [isPrinting, selectedOrder, danhSachDonHangDaChon, isDataLoaded]);

  const handlePrintClick = (record) => {
    setSelectedOrder(record);
    setIsDataLoaded(false);
    dispatch({
      type: LAY_CHI_TIET_DON_SHIP_SAGA_V2,
      id: record?.Id,
      callback: () => {
        setIsDataLoaded(true);
      },
    });
    setIsPrinting(true);
  };

  const columns = [
    // {
    //   title: "Mã",
    //   dataIndex: "Id",
    //   key: "Id",
    // },
    {
      title: "Mã Đơn Ship",
      dataIndex: "MaDonShipNoiBo",
      key: "MaDonShipNoiBo",
    },
    {
      title: " Mã vận đơn",
      dataIndex: "MaVanDon",
      key: "MaVanDon",
    },
    {
      title: "Đơn vị vận chuyển",
      dataIndex: "TenDonViVanChuyen",
      key: "TenDonViVanChuyen",
    },
    {
      title: "Số Đơn",
      dataIndex: "SoDon",
      key: "SoDon",
    },
    {
      title: "Số SP",
      dataIndex: "SoSanPham",
      key: "SoSanPham",
    },
    {
      title: "Tiền COD",
      dataIndex: "TienCOD",
      key: "TienCOD",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "Phí ship",
      dataIndex: "PhiShip",
      key: "PhiShip",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "Thông Tin Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            Tên KH:{" "}
            <a target="_blank" href={record?.LinkFacebook}>
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.tbKhachHang?.TenKhachHang}
              </span>
            </a>
          </div>
          <div>
            SĐT:{" "}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.tbKhachHang?.SoDienThoai}
            </span>
          </div>

          <span>
            Địa Chỉ Nhận Hàng:
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.tbKhachHang?.DiaChiNhanHang}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: "Ngày ship đi",
      dataIndex: "NgayShip",
      key: "NgayShip",
      render: (text) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Tình Trạng Ship",
      dataIndex: "TinhTrangDonShip",
      key: "TinhTrangDonShip",
      render: (text, record) => {
        return (
          <>
            <span>{text}</span>{" "}
            <Button
              size="small"
              type="primary"
              shape="circle"
              onClick={() => {
                let idTrangThai = 2;
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <h6>Chọn Trạng Thái Đơn Ship {record?.MaVanDon}</h6>
                      <Select
                        style={{ width: "100%" }}
                        defaultValue={idTrangThai}
                        placeholder="Tình trạng Đơn Ship"
                        onChange={(value) => {
                          idTrangThai = value;
                        }}
                      >
                        {danhSachTinhTrangDonShip?.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item?.Id}>
                              {item?.Ten}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <h6 className="mt-2 text text-warning">
                        Bạn có chắc chắn muốn thay đổi trạng thái đơn Ship{" "}
                        {record?.MaDonShipNoiBo} không?
                      </h6>
                    </>
                  ),
                  onOk() {
                    dispatch({
                      type: CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA,
                      idDonShip: record?.Id,
                      idTrangThai,
                    });
                  },
                  onCancel() {
                    //   console.log("Cancel");
                  },
                });
              }}
            >
              <Tooltip title="Chỉnh Sửa Tình Trạng ship">
                <i className="fa-solid fa-pen-to-square"></i>
              </Tooltip>
            </Button>
          </>
        );
      },
    },
    {
      title: "Thông tin Tạo",
      dataIndex: "NgayTao",
      key: "NgayTao",
      render: (text, record) => {
        const ngayTao = text ? dayjs(text).format("DD/MM/YYYY") : "";
        return (
          <>
            <div>
              Ngày tạo: <span className="fw-bold">{ngayTao}</span>
            </div>
            <div>
              Người tạo: <span className="fw-bold">{record?.TenNguoiTao}</span>
            </div>
            <div>
              mã NV:
              <span className="fw-bold">{record?.MaNhanVienNguoiTao}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Chức năng",
      key: "Select",
      render: (text, record) => {
        return (
          <>
            <>
              <div className="row">
                <button
                  className="btn me-2 btn-primary col-6"
                  onClick={() => {
                    history.push(`/giao-hang/chinh-sua-don-ship/${record?.Id}`);
                  }}
                >
                  <Tooltip title="Chỉnh Sửa Đơn Ship">
                    <FormOutlined style={{ fontSize: 17 }} />
                  </Tooltip>
                </button>
                <Popconfirm
                  title="Xóa Đơn Ship"
                  description={`Bạn có chắc chắn muốn xóa Đơn Ship "${record?.MaDonShipNoiBo}"?`}
                  onConfirm={() => {
                    dispatch({
                      type: XOA_DON_SHIP_SAGA,
                      id: record?.Id,
                    });
                  }}
                  onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                >
                  <button className="btn btn-danger me-2 col-6">
                    <Tooltip title="Xóa Đơn Ship">
                      <i className="fa-solid fa-trash"></i>
                    </Tooltip>
                  </button>
                </Popconfirm>

                <Button
                  className="col-6"
                  onClick={() => {
                    // dispatch({
                    //   type: LAY_CHI_TIET_DON_SHIP_SAGA,
                    //   id: record?.Id,
                    // });
                    handlePrintClick(record);
                  }}
                >
                  <Tooltip title="In Đơn Ship">
                    <i className="fa-solid fa-print"></i>
                  </Tooltip>
                </Button>
              </div>
            </>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Danh Sách Giao Hàng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/giao-hang/them_moi_don_ship");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Đơn Ship
        </button>
      </div>

      <div className="search-filters my-4 row">
        <div className="col-md-4 ">
          <h6>Lọc theo Ngày Tạo</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>

        <div className="col-md-2">
          <h6>Mã Vẫn Đơn</h6>
          <Input
            value={filters.maVanDon}
            onChange={(e) =>
              setFilters({ ...filters, maVanDon: e.target.value })
            }
            placeholder="Nhập Mã Nhận Đơn"
          />
        </div>

        <div className="col-md-2 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachDonShip}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
        onChange={handleTableChange}
      />
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <ShippingOrderPrint order={selectedOrder} />
        </div>
      </div>
    </div>
  );
}
