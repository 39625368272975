import { LOGIN_API, LOGOUT_API } from "../types/LoginType"


export const loginAction = (username, password) => {
    return {
        type: LOGIN_API,
        userLogin: {
            username,
            password
        }
    }
}

export const logoutAction = () => {
    return {
        type: LOGOUT_API
    }
}