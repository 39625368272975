import { Input, Button } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  BankOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../../redux/types/GlobalType";
import { THEM_KHACH_HANG_SAGA } from "../../../redux/types/KhachHangType";

function FormThemKhachHang(props) {
  const dispatch = useDispatch();

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="container">
      <h6 className="ms-1 mt-3">Tên Khách Hàng</h6>
      <div className="d-flex">
        <Input
          name="tenKhachHang"
          value={values.tenKhachHang}
          size="large"
          placeholder="Tên Khách Hàng"
          prefix={<UserOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.tenKhachHang}</div>

      <h6 className="mt-3 ms-1">Số Điện Thoại</h6>
      <div className="d-flex">
        <Input
          value={values.soDienThoai}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="soDienThoai"
          size="large"
          placeholder="Số điện thoại"
          prefix={<PhoneOutlined />}
        />
      </div>
      <div className="text-danger">{errors.soDienThoai}</div>

      <h6 className="mt-3 ml-1">Địa Chỉ Nhận Hàng</h6>
      <div className="d-flex">
        <Input
          value={values.diaChiNhanHang}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="diaChiNhanHang"
          size="large"
          placeholder="Địa Chỉ Nhận Hàng"
          prefix={<BankOutlined />}
        />
      </div>
      <div className="text-danger">{errors.diaChiNhanHang}</div>

      <h6 className="mt-3 ml-1">Link Facebook</h6>
      <div className="d-flex">
        <Input
          onChange={handleChange}
          value={values.linkFaceBook}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="linkFaceBook"
          size="large"
          placeholder="Link Facebook"
          prefix={<FacebookOutlined />}
        />
      </div>
      <div className="text-danger">{errors.linkFaceBook}</div>
    </form>
  );
}

const FormThemKhachHangFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    tenKhachHang: "",
    soDienThoai: "",
    diaChiNhanHang: "",
    linkFaceBook: "",
  }),
  validationSchema: Yup.object().shape({
    tenKhachHang: Yup.string().required("Tên Khách Hàng bắt buộc"),
    diaChiNhanHang: Yup.string().required("Địa Chỉ Nhận Hàng bắt buộc"),
    soDienThoai: Yup.number()
      .typeError("Số điện thoại không đúng định dạng")
      .min(10, "số điện thoại ít nhất 10 chữ số")
      .required("Số điện thoại bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.dispatch({
      type: THEM_KHACH_HANG_SAGA,
      newKhachHang: { ...values },
      callback: (success) => {
        if (success) {
          resetForm();
        }
      },
    });
  },
})(FormThemKhachHang);

export default connect()(FormThemKhachHangFormik);
