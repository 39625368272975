// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: #f8f9fa;
    color: #343a40;
  }
  
  .not-found-title {
    font-size: 10rem;
    margin-bottom: 1rem;
  }
  
  .not-found-description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    text-decoration: none;
    font-size: 1.2rem;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Not/NotFoundPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,oBAAoB;IACpB,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":[".not-found-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    text-align: center;\r\n    background: #f8f9fa;\r\n    color: #343a40;\r\n  }\r\n  \r\n  .not-found-title {\r\n    font-size: 10rem;\r\n    margin-bottom: 1rem;\r\n  }\r\n  \r\n  .not-found-description {\r\n    font-size: 1.5rem;\r\n    margin-bottom: 2rem;\r\n  }\r\n  \r\n  .not-found-link {\r\n    text-decoration: none;\r\n    font-size: 1.2rem;\r\n    color: #007bff;\r\n    border: 2px solid #007bff;\r\n    padding: 0.5rem 1rem;\r\n    border-radius: 5px;\r\n    transition: background-color 0.3s, color 0.3s;\r\n  }\r\n  \r\n  .not-found-link:hover {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
