import { Input, Select, InputNumber } from "antd";
import {
  InboxOutlined,
  LinkOutlined,
  DollarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../../redux/types/GlobalType";
import { EDIT_NGUON_HANG_SAGA } from "../../../redux/types/NguonHangType";

const { Option } = Select;

function FormEditNguonHang(props) {
  const { danhSachTienTe } = useSelector((state) => state.TienTeReducer);
  const dispatch = useDispatch();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="container">
      <h6 className="ms-1 mt-3">Id</h6>
      <div className="d-flex">
        <Input
          name="Id"
          value={values.Id}
          size="large"
          disabled
          placeholder="Id"
          prefix={<InboxOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.Id}</div>
      <h6 className="ms-1 mt-3">Tên Nguồn Hàng</h6>
      <div className="d-flex">
        <Input
          name="ten"
          value={values.ten}
          size="large"
          placeholder="Tên Nguồn Hàng"
          prefix={<InboxOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.ten}</div>

      <h6 className="mt-3 ms-1">Link Nguồn Hàng</h6>
      <div className="d-flex">
        <Input
          value={values.link}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="link"
          size="large"
          placeholder="Link Nguồn Hàng"
          prefix={<LinkOutlined />}
        />
      </div>
      <div className="text-danger">{errors.link}</div>

      <h6 className="mt-3 ml-1">Giá Theo Cân</h6>
      <div className="d-flex">
        <InputNumber
          value={values.giaTheoCan}
          onChange={(value) => setFieldValue("giaTheoCan", value)}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="giaTheoCan"
          size="large"
          placeholder="Giá Theo Cân"
          prefix={<DollarOutlined />}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      </div>
      <div className="text-danger">{errors.giaTheoCan}</div>

      <h6 className="mt-3 ms-1">xPath Ảnh</h6>
      <div className="d-flex">
        <Input
          value={values.xPathAnh}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="xPathAnh"
          size="large"
          placeholder="xPath Ảnh"
          prefix={<LinkOutlined />}
        />
      </div>
      <div className="text-danger">{errors.xPathAnh}</div>

      <h6 className="mt-3 ms-1">xPath Giá Web</h6>
      <div className="d-flex">
        <Input
          value={values.xPathGiaWeb}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="xPathGiaWeb"
          size="large"
          placeholder="xPath Giá Web"
          prefix={<LinkOutlined />}
        />
      </div>
      <div className="text-danger">{errors.xPathGiaWeb}</div>

      <h6 className="mt-3 ms-1">Ghi Chú</h6>
      <div className="d-flex">
        <Input
          value={values.ghiChu}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="ghiChu"
          size="large"
          placeholder="Ghi Chú"
          prefix={<EditOutlined />}
        />
      </div>
      <div className="text-danger">{errors.ghiChu}</div>

      <h6 className="mt-3 ms-1">Chọn Loại Tiền Tệ</h6>
      <div className="d-flex">
        <Select
          defaultValue={values.idLoaiTienTe} // Giá trị mặc định
          style={{ width: "100%", minWidth: 450 }}
          size="large"
          onChange={(value) => setFieldValue("idLoaiTienTe", value)}
        >
          {danhSachTienTe?.map((item, key) => {
            return (
              <Option key={key} value={item?.Id}>
                {item?.TienTe}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="text-danger">{errors.idLoaiTienTe}</div>
    </form>
  );
}

const FormEditNguonHangFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    Id: props?.nguonHangEdit?.Id,
    ten: props?.nguonHangEdit?.Ten,
    link: props?.nguonHangEdit?.Link,
    giaTheoCan: props?.nguonHangEdit?.GiaTheoCan,
    xPathAnh: props?.nguonHangEdit?.xPathAnh,
    xPathGiaWeb: props?.nguonHangEdit?.xPathGiaWeb,
    ghiChu: props?.nguonHangEdit?.GhiChu,
    idLoaiTienTe: props?.nguonHangEdit?.IdLoaiTienTe,
  }),
  validationSchema: Yup.object().shape({
    ten: Yup.string().required("Tên Nguồn Hàng bắt buộc"),
    link: Yup.string().required("Link Nguồn Hàng bắt buộc"),
    giaTheoCan: Yup.number()
      .typeError("Giá Theo Cân không đúng định dạng")
      .required("Giá Theo Cân bắt buộc"),
    idLoaiTienTe: Yup.string().required("Loại Tiền Tệ bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    setSubmitting(false);

    resetForm({
      values: "",
    });
    props.dispatch({
      type: EDIT_NGUON_HANG_SAGA,
      nguonHangUpdate: { ...values },
    });
  },
})(FormEditNguonHang);

const mapStateToProp = (state) => {
  return {
    nguonHangEdit: state.NguonHangReducer?.nguonHangEdit,
  };
};

export default connect(mapStateToProp)(FormEditNguonHangFormik);
