import { SET_DANH_SACH_KHACH_HANG, SET_EDIT_KHACH_HANG } from "../types/KhachHangType"

const initialState = {
    danhSachKhachHang: [
    ],
    khachHangEdit: {

    },
    total: 0
}

export const KhachHangReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_KHACH_HANG: {
            state.danhSachKhachHang = action.danhSachKhachHang;
            state.total = action?.total
            return { ...state }
        }

        case SET_EDIT_KHACH_HANG: {
            state.khachHangEdit = action?.khachHangEdit;
            return { ...state }
        }

        default:
            return state
    }
}
