import { Input, Select, InputNumber, Button } from "antd";
import { DollarOutlined, EditOutlined } from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  CHINH_SUA_CHI_PHI_SAGA,
  LAY_DANH_SACH_NHOM_CHI_PHI_SAGA,
} from "../../redux/types/ChiPhiType";

const { Option } = Select;

function FormChinhSuaChiPhi(props) {
  const { danhSachNhomChiPhi } = useSelector((state) => state.ChiPhiReducer);
  const dispatch = useDispatch();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = props;

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_NHOM_CHI_PHI_SAGA });
  }, []);

  const TinhTuDong = async () => {
    try {
      setFieldValue("thanhTien", values?.donGia * values?.soLuong);
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <h4 className="mt-4">Chỉnh Sửa Chi Phí</h4>

      <div className="row mt-3">
        <div className="col-md-6">
          <h6>Chọn Nhóm Chi Phí</h6>
          <Select
            value={values.idNhom}
            style={{ width: "100%" }}
            size="large"
            onChange={(value) => setFieldValue("idNhom", value)}
            placeholder="Chọn Nhóm Chi Phí"
          >
            {danhSachNhomChiPhi?.map((item, key) => (
              <Option key={key} value={item?.Id}>
                {item?.Ten}
              </Option>
            ))}
          </Select>
          <div className="text-danger">{errors.idNhom}</div>

          <div className="row mt-3">
            <div className="col-md-6">
              <h6>Đơn Giá</h6>
              <InputNumber
                name="donGia"
                value={values.donGia}
                size="large"
                placeholder="Đơn Giá"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => setFieldValue("donGia", value)}
                style={{ width: "100%" }}
              />
              <div className="text-danger">{errors.donGia}</div>
            </div>

            <div className="col-md-6">
              <h6>Số Lượng</h6>
              <InputNumber
                name="soLuong"
                value={values.soLuong}
                size="large"
                placeholder="Số lượng"
                min={0}
                onChange={(value) => setFieldValue("soLuong", value)}
                style={{ width: "100%" }}
              />
              <div className="text-danger">{errors.soLuong}</div>
            </div>
          </div>

          <h6 className="mt-3">Thành Tiền (VND)</h6>
          <div className="row">
            <InputNumber
              name="thanhTien"
              value={values.thanhTien}
              size="large"
              placeholder="Thành Tiền"
              prefix={<DollarOutlined />}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => setFieldValue("thanhTien", value)}
              className="col-md-6"
            />
            <Button
              className="ms-md-2 col-md-5"
              type="primary"
              size="large"
              onClick={TinhTuDong}
              style={{ backgroundColor: "#5a5a5a", color: "white" }}
            >
              Tính Tự Động
            </Button>
          </div>
          <div className="text-danger">{errors.thanhTien}</div>
        </div>

        <div className="col-md-6">
          <h6>Nội Dung</h6>
          <Input
            name="noiDung"
            value={values.noiDung}
            size="large"
            placeholder="Nội Dung"
            prefix={<EditOutlined />}
            onChange={handleChange}
          />
          <div className="text-danger">{errors.noiDung}</div>
          <h6 className="mt-3">Ghi Chú</h6>
          <Input
            name="ghiChu"
            value={values.ghiChu}
            size="large"
            placeholder="Ghi chú"
            prefix={<EditOutlined />}
            onChange={handleChange}
          />
          <div className="text-danger">{errors.ghiChu}</div>
          <h6 className="mt-3">Mã Tham Chiếu (Đối Với Order)</h6>
          <Input
            name="maThamChieu"
            value={values.maThamChieu}
            size="large"
            placeholder="Mã Tham Chiếu"
            prefix={<EditOutlined />}
            onChange={handleChange}
          />
          <div className="text-danger">{errors.maThamChieu}</div>
        </div>
      </div>
      <Button
        htmlType="submit"
        size="large"
        className="mt-3"
        style={{
          width: "100%",
          backgroundColor: "#1890ff",
          color: "white",
        }}
      >
        Sửa Chi Phí
      </Button>
    </form>
  );
}

const FormikFormChinhSuaChiPhi = withFormik({
  mapPropsToValues: (props) => {
    return {
      id: props?.chiPhiEdit?.Id,
      idNhom: props?.chiPhiEdit?.IdNhom,
      noiDung: props?.chiPhiEdit?.NoiDung,
      soLuong: props?.chiPhiEdit?.SoLuong,
      donGia: props?.chiPhiEdit?.DonGia,
      thanhTien: props?.chiPhiEdit?.ThanhTien,
      ghiChu: props?.chiPhiEdit?.GhiChu,
      maThamChieu: props?.chiPhiEdit?.MaThamChieu,
    };
  },

  validationSchema: Yup.object().shape({
    idNhom: Yup.string().required("Nhóm Chi Phí không được để trống"),
    donGia: Yup.number()
      .required("Đơn Giá không được để trống")
      .min(0, "Đơn Giá lớn hơn hoặc bằng 0"),
    soLuong: Yup.number()
      .required("Số lượng không được để trống")
      .min(0, "Số lượng phải lớn hơn hoặc bằng 0"),
    thanhTien: Yup.number()
      .required("Thành Tiền không được để trống")
      .min(0, "Thành tiền phải lớn hơn hoặc bằng 0"),
  }),

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.dispatch({
      type: CHINH_SUA_CHI_PHI_SAGA,
      chiPhiEdit: values,
    });
    resetForm();
  },
})(FormChinhSuaChiPhi);

const mapStateToProp = (state) => {
  return {
    chiPhiEdit: state.ChiPhiReducer?.chiPhiEdit,
  };
};

export default connect(mapStateToProp)(FormikFormChinhSuaChiPhi);
