import { DOMAIN } from "../utils/settings/config";
import authorizedAxiosInstance from "./authorizedAxios";

const layDanhSachDonHangPhanTrangAPI = async (
    page = 1,
    pageSize = 30,
    idNguonHang = '',
    idTinhTrang = '',
    startDate = '',
    endDate = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/order/lay_danh_sach_don_hang_theo_order`, {
        idNguonHang,
        idTinhTrang,
        startDate,
        endDate,
        page,
        pageSize
    });
}

const layTiGiaAPI = async (idNguonHang = 1) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/order/lay_ti_gia_hien_tai`, {
        idNguonHang
    });
}

const taoOrderAPI = async (idNguonHang = "",
    maOrder = "",
    trackingNumber = "",
    soTien = "",
    shipWeb = "",
    ngayNhanDonHangDuKien = "",
    tiGiaHienTai = "",
    danhSachDonHang = "") => {

    return await authorizedAxiosInstance.post(`${DOMAIN}/order/them_order`, {
        idNguonHang,
        maOrder,
        trackingNumber,
        soTien,
        shipWeb,
        ngayNhanDonHangDuKien,
        tiGiaHienTai,
        danhSachDonHang
    })
}

const layDanhSachOrderKeywordPhanTrangAPI = async (
    page = 1,
    pageSize = 30,
    idNguonHang = '',
    idTinhTrang = '',
    startDate = '',
    endDate = ''
) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/order/lay_danh_sach_order_by_keyword_phan_trang`, {
        idNguonHang,
        idTinhTrang,
        startDate,
        endDate,
        page,
        pageSize
    });
}

const xoaOrderAPI = async (id) => {
    return await authorizedAxiosInstance.delete(`${DOMAIN}/order/xoa_order/${id}`);
}

const layOrderChiTietAPI = async (id) => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/order/lay_chi_tiet_order/${id}`);
}

const xoaThayDoiTrangThaiDonHangOrderAPI = async (idOrder, idDonHang, idTrangThai) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/order/xoa_thay_doi_trang_thai_don_hang_trong_order`, {
        idOrder,
        idDonHang,
        idTrangThai
    })
}

const chinhSuaOrderAPI = async (idNguonHang = "",
    maOrder = "",
    trackingNumber = "",
    soTien = "",
    shipWeb = "",
    ngayNhanDonHangDuKien = "",
    tiGiaHienTai = "",
    danhSachDonHang = "",
    idOrder = '') => {

    return await authorizedAxiosInstance.put(`${DOMAIN}/order/chinh_sua_order/${idOrder}`, {
        idNguonHang,
        maOrder,
        trackingNumber,
        soTien,
        shipWeb,
        ngayNhanDonHangDuKien,
        tiGiaHienTai,
        danhSachDonHang
    })
}

export const orderService = {
    layDanhSachDonHangPhanTrangAPI,
    layTiGiaAPI,
    taoOrderAPI,
    layDanhSachOrderKeywordPhanTrangAPI,
    xoaOrderAPI,
    layOrderChiTietAPI,
    xoaThayDoiTrangThaiDonHangOrderAPI,
    chinhSuaOrderAPI,
}