import { Notification } from "../../utils/Notifications/Notification";
import { NOTIFICATION_TYPE } from "../../utils/settings/config";
import { RESET_ORDER, SET_DANH_SACH_DON_HANG_ORDER, SET_DANH_SACH_ORDER, SET_ORDER_EDIT, THEM_DON_HANG_DA_CHON, XOA_DON_HANG_DA_CHON, XOA_HET_DON_HANG_DA_CHON } from "../types/OderType";

const initialState = {
    danhSachDonHang: [],
    totalCount: 0,
    danhSachTinhTrangDonHang: [
        {
            "Id": 1,
            "Ten": "Mới lên đơn"
        },
        {
            "Id": 3,
            "Ten": "Đợi mua lại"
        },
        {
            "Id": 4,
            "Ten": "Không mua được"
        },
        {
            "Id": 9,
            "Ten": "Nguồn hàng ship thiếu"
        }
    ],
    danhSachDonHangDaChon: [],
    danhSachTinhTrangDonHangFull: [
        {
            "Id": 1,
            "Ten": "Mới lên đơn"
        },
        {
            "Id": 2,
            "Ten": "Đã order thành công"
        },
        {
            "Id": 3,
            "Ten": "Đợi mua lại"
        },
        {
            "Id": 4,
            "Ten": "Không mua được"
        },
        {
            "Id": 5,
            "Ten": "Hàng đã về, đợi ship"
        },
        {
            "Id": 6,
            "Ten": "Đã ship đi"
        },
        {
            "Id": 7,
            "Ten": "Đã hoàn thành"
        },
        {
            "Id": 8,
            "Ten": "Trả hàng"
        },
        {
            "Id": 9,
            "Ten": "Nguồn hàng ship thiếu"
        }
    ],
    danhSachOrder: [],
    danhSachTinhTrangOrder: [
        {
            "Id": 1,
            "Ten": "Đã order Thành công"
        },
        {
            "Id": 2,
            "Ten": "Đã nhận hàng"
        },
    ],
    orderEdit: {},
    totalCountOrder: 0
}

export const OrderReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_DON_HANG_ORDER: {
            state.danhSachDonHang = action.danhSachDonHang;
            state.totalCount = action?.totalCount;
            return { ...state }
        }


        case THEM_DON_HANG_DA_CHON: {
            const { payload } = action;
            const { danhSachDonHangDaChon } = state;

            if (danhSachDonHangDaChon.length === 0 ||
                danhSachDonHangDaChon[0].IdNguonHang === payload.IdNguonHang) {

                const updatedDanhSach = [...danhSachDonHangDaChon, payload];

                // Sort the list by Id in ascending order
                updatedDanhSach.sort((a, b) => a.Id - b.Id);

                return {
                    ...state,
                    danhSachDonHangDaChon: updatedDanhSach,
                };
            } else {
                Notification(NOTIFICATION_TYPE.error, "không thể thêm đơn hàng có nguồn hàng khác");
                // Return the current state without any changes if IdNguonHang doesn't match
                return state;
            }
        }
        case XOA_DON_HANG_DA_CHON:
            return {
                ...state,
                danhSachDonHangDaChon: state.danhSachDonHangDaChon.filter(
                    (order) => order.Id !== action.payload.Id
                ),
            };
        case XOA_HET_DON_HANG_DA_CHON:
            return {
                ...state,
                danhSachDonHangDaChon: [],
            };

        case SET_DANH_SACH_ORDER: {
            state.danhSachOrder = action?.danhSachOrder;
            state.totalCountOrder = action?.totalCountOrder;
            return { ...state }
        }

        case SET_ORDER_EDIT: {
            state.danhSachDonHangDaChon = action?.danhSachDonHangDaChon?.sort((a, b) => a.Id - b.Id);
            state.orderEdit = action?.orderEdit;

            return { ...state }
        }

        case RESET_ORDER: {
            state.danhSachDonHangDaChon = [];
            state.orderEdit = {}
            return { ...state }
        }

        default:
            return state
    }
}
