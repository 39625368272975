import { LAY_DANH_SACH_NGUOI_DUNG, SET_DANH_SACH_VAI_TRO, SET_USER_EDIT } from "../types/QuanTriType"

const initialState = {
    danhSachNguoiDung: [{
        "MaNhanVien": "NV01",
        "TenNhanVien": "Duong Xuan Quyet",
        "Username": "quyet",
        "is_activated": true,
        "roles": [
            "ADMIN"
        ]
    },
    {
        "Id": 1,
        "MaNhanVien": "NV01",
        "TenNhanVien": "Duong Xuan Quyet",
        "Username": "quyet1",
        "is_activated": true,
        "roles": [
            "nhan_vien_kho",
            "ke_toan"
        ]
    }],
    userEdit: {
        "MaNhanVien": "NV01",
        "TenNhanVien": "Duong Xuan Quyet",
        "Username": "quyet1",
        "is_activated": true,
        "roles": [
            "nhan_vien_kho",
            "ke_toan"
        ]
    },
    danhSachVaiTro: []
}

export const QuanTriReducer = (state = initialState, action) => {
    switch (action.type) {

        case LAY_DANH_SACH_NGUOI_DUNG:
            state.danhSachNguoiDung = action.danhSachNguoiDung
            return { ...state }


        case SET_USER_EDIT: {
            state.userEdit = action.userEdit;
            return { ...state }
        }

        case SET_DANH_SACH_VAI_TRO: {
            state.danhSachVaiTro = action.danhSachVaiTro;
            return { ...state }
        }

        default:
            return state
    }
}
