import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachKhachHangAPI = async (page, pageSize, keyword) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}//khach_hang/lay_danh_sach_khach_hang_by_keyword`, {
        page,
        pageSize,
        keyword
    });
}

const layDanhSachKhachHangTheoKeywordAPI = async (keyWord) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/khach_hang/lay_danh_sach_khach_hang_by_keyword`, { keyWord })
}

const themKhachHangAPI = async (newKhachHang) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/khach_hang/them_khach_hang`, newKhachHang);
}

const chinhSuaKhachHangAPI = async (id, khachHangUpdate) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/khach_hang/sua_thong_tin_khach_hang/${id}`, khachHangUpdate);
}

const xoaKhachHangAPI = async (id) => {
    return await authorizedAxiosInstance.delete(`${DOMAIN}/khach_hang/xoa_thong_tin_khach_hang/${id}`);
}

export const khachHangService = {
    layDanhSachKhachHangAPI,
    layDanhSachKhachHangTheoKeywordAPI,
    themKhachHangAPI,
    chinhSuaKhachHangAPI,
    xoaKhachHangAPI
}