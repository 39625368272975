// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-menu-item-selected {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.ant-menu-item:hover {
  background-color: #ffb6c1;
}

.ant-menu-item {
  background-color: #ffb6c1;
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/templates/HomeTemplate/HomeTemplate.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".ant-menu-item-selected {\r\n  background-color: #ff69b4 !important;\r\n  color: #fff !important;\r\n}\r\n\r\n.ant-menu-item:hover {\r\n  background-color: #ffb6c1;\r\n}\r\n\r\n.ant-menu-item {\r\n  background-color: #ffb6c1;\r\n  color: #000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
