import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachNguonHangAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/nguon_hang/danh_sach_nguon_hang`);
}

const timKiemNguonHangbyKeywordAPI = async (keyword) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/nguon_hang/lay_nguon_hang_by_keyword`, { keyword })
}

const themNguonHangAPI = async (newNguongHang) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/nguon_hang/them_nguon_hang`, { ...newNguongHang });
}

const xoaNguonHangAPI = async (id) => {
    return await authorizedAxiosInstance.delete(`${DOMAIN}/nguon_hang//xoa_nguon_hang/${id}`);
}

const chinhSuaNguonHangAPI = async (id, dataUpdate) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/nguon_hang/sua_nguon_hang/${id}`, { ...dataUpdate });
}

export const nguonHangService = {
    layDanhSachNguonHangAPI,
    timKiemNguonHangbyKeywordAPI,
    themNguonHangAPI,
    xoaNguonHangAPI,
    chinhSuaNguonHangAPI,
}