import {
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  InputNumber,
  Divider,
} from "antd";
import {
  InboxOutlined,
  LinkOutlined,
  DollarOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { SET_SUBMIT } from "../../../redux/types/GlobalType";
import { CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA } from "../../../redux/types/NhanDonType";

const { Option } = Select;
const DEFAULT_IMAGE = "https://via.placeholder.com/250"; // Đường dẫn hình ảnh mặc định

function FormChinhSuaDonHangNhanHang(props) {
  const dispatch = useDispatch();

  const [imgSrc, setImgSrc] = useState(DEFAULT_IMAGE); // Set hình ảnh mặc định

  const { values, errors, handleChange, handleSubmit, setFieldValue } = props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  useEffect(() => {
    // Khi giá trị của linkAnh thay đổi, cập nhật hình ảnh
    if (values.linkAnh) {
      setImgSrc(values.linkAnh);
    } else {
      setImgSrc(DEFAULT_IMAGE); // Sử dụng hình ảnh mặc định nếu không có link
    }
  }, [values.linkAnh]);

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h6>Mã Đơn Hàng</h6>
          <Input
            name="maDonHang"
            value={values.maDonHang}
            size="large"
            disabled
            onChange={handleChange}
          />
          <div className="text-danger">{errors.maDonHang}</div>
        </div>
        <div className="col-6">
          <h6>Tên Nguồn Hàng</h6>
          <Input
            name="TenNguonHang"
            value={values.TenNguonHang}
            size="large"
            disabled
            onChange={handleChange}
          />
          <div className="text-danger">{errors.TenNguonHang}</div>
        </div>
      </div>
      <h6 className="mt-3">Tình Trạng Đơn Hàng</h6>
      <Select
        value={values.idTinhTrang}
        style={{ width: "100%" }}
        size="large"
        onChange={(value) => setFieldValue("idTinhTrang", value)}
        placeholder="Chọn Tình Trạng"
      >
        <Option value={5}>Hàng đã về, đợi ship</Option>
        <Option value={9}>Nguồn hàng ship thiếu</Option>
        {/* <Option value={2}>Đã Order Thành Công</Option> */}
      </Select>

      <h6 className="mt-3">Hình Ảnh Sản phẩm</h6>
      <div
        style={{
          width: "235px",
          height: "235px",
          border: "1px solid #d9d9d9",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={imgSrc}
          alt="Product"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>

      <div className="row mt-3">
        <div className="col-6">
          <h6>Size</h6>
          <Input
            disabled
            name="size"
            value={values.size}
            size="large"
            placeholder="Size"
            onChange={handleChange}
          />
          <div className="text-danger">{errors.size}</div>
        </div>
        <div className="col-6">
          <h6>Số Lượng</h6>
          <InputNumber
            name="soLuong"
            value={values.soLuong}
            size="large"
            placeholder="Số lượng"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setFieldValue("soLuong", value)}
            style={{ width: "100%" }}
          />
          <div className="text-danger">{errors.soLuong}</div>
        </div>
        <div className="col-6 mt-2">
          <h6>Cân Nặng(Lạng)</h6>
          <InputNumber
            name="canNang"
            value={values.canNang}
            size="large"
            placeholder="Cân Nặng"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setFieldValue("canNang", value)}
            style={{ width: "100%" }}
          />
          <div className="text-danger">{errors.canNang}</div>
        </div>
        <div className="col-6 mt-2">
          <h6>Giá Theo Cân(VND)</h6>
          <Input
            disabled
            name="giaTheoCan"
            value={values.giaTheoCan}
            size="large"
            placeholder="Giá theo cân"
            onChange={handleChange}
          />
          <div className="text-danger">{errors.giaTheoCan}</div>
        </div>
        <div className="col-8 mt-3">
          <h6>Tổng Tiền Cân(VND)</h6>
          <InputNumber
            name="tongTienCan"
            value={values.tongTienCan}
            size="large"
            placeholder="Tổng Tiền cân"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setFieldValue("tongTienCan", value)}
            style={{ width: "100%" }}
          />
          <div className="text-danger">{errors.tongTienCan}</div>
        </div>
        <div className="col-4 mt-5">
          <Button
            style={{ width: "100%" }}
            className="btn btn-primary"
            onClick={() => {
              const total =
                values?.canNang * values?.giaTheoCan * values?.soLuong;
              setFieldValue("tongTienCan", Math.round(total));
            }}
          >
            Lấy Giá tham Khảo
          </Button>
        </div>
      </div>
    </form>
  );
}

const FormikFormChinhSuaDonHangNhanHang = withFormik({
  mapPropsToValues: (props) => {
    return {
      idDonHang: props?.donHangEdit?.Id,
      maDonHang: props?.donHangEdit?.MaDonHang,
      TenNguonHang: props?.donHangEdit?.TenNguonHang,
      giaTheoCan: props?.donHangEdit?.tbNguonHang?.GiaTheoCan,
      IdNguonHang: props?.donHangEdit?.IdNguonHang,
      idOrder: props?.donHangEdit?.idOrder,
      linkAnh: props?.donHangEdit?.LinkAnh,
      size: props?.donHangEdit?.Size,
      soLuong: props?.donHangEdit?.SoLuong,
      idTinhTrang: 5,
      canNang: props?.donHangEdit?.CanNang,
      tongTienCan: props?.donHangEdit?.TongTienCan,
    };
  },

  validationSchema: Yup.object().shape({
    canNang: Yup.number()
      .required("Cân nặng không được để trống")
      .min(0, "Cân nặng phải lớn hơn hoặc bằng 0"),
    tongTienCan: Yup.number()
      .required("Tổng tiền Cân không được để trống")
      .min(0, "Tổng tiền Cân phải lớn hơn hoặc bằng 0"),
    soLuong: Yup.number()
      .required("Số Lượng không được để trống")
      .min(0, "Số Lượng phải lớn hơn hoặc bằng 0"),
  }),

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.dispatch({
      type: CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA,
      donHangEdit: values,
    });
  },
})(FormChinhSuaDonHangNhanHang);

const mapStateToProp = (state) => {
  return {
    donHangEdit: state.NhanDonReducer?.donHangEdit,
  };
};

export default connect(mapStateToProp)(FormikFormChinhSuaDonHangNhanHang);
