import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { CHINH_SUA_TI_GIA_SAGA, LAY_DANH_SACH_TIEN_TE_SAGA, SET_DANH_SACH_TIEN_TE } from '../types/TienTeType';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { Notification } from '../../utils/Notifications/Notification';
import { tienTeService } from '../../services/tienTeService';
import { CLEAN_CONTENT_DRAWER } from '../types/GlobalType';

function* layDanhSachTienTeSaga() {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return tienTeService.layDanhSachTienTeAPI();
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_TIEN_TE,
                danhSachTienTe: data?.data?.danhSachTienTe
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách tiền tệ thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachTienTeSaga() {
    yield takeLatest(LAY_DANH_SACH_TIEN_TE_SAGA, layDanhSachTienTeSaga);
}

function* chinhSuaTiGiaTienTeSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log(action);
        const { tiGiaUpdate } = action;
        const { data, status } = yield call(() => {
            return tienTeService.chinhSuaThongTinTienTeAPI(tiGiaUpdate?.Id, tiGiaUpdate?.TiGia);
        })

        // console.log(data);
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_TIEN_TE_SAGA
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Tỉ Giá Thành Công!")
        }


    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Tỉ Giá Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaTiGiaTienTeSaga() {
    yield takeLatest(CHINH_SUA_TI_GIA_SAGA, chinhSuaTiGiaTienTeSaga);
}
