import { SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON, SET_DANH_SACH_ORDER_CHON_NHAN_DON, SET_DANH_SACH_ORDER_TRANG_NHAN_DON, SET_DON_HANG_EDIT_NHAN_DON } from "../types/NhanDonType";

const initialState = {
    danhSachNguonHang: [],
    danhSachOrder: [],
    totalCountOrder: 0,
    danhSachDonHangDaChon: [],
    orderEdit: {},
    donHangEdit: {},
}

export const NhanDonReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON: {
            state.danhSachNguonHang = action?.danhSachNguonHang
            return { ...state }
        }

        case SET_DANH_SACH_ORDER_TRANG_NHAN_DON: {
            state.danhSachOrder = action?.danhSachOrder;
            state.totalCountOrder = action?.totalCountOrder
            return { ...state }
        }

        case SET_DANH_SACH_ORDER_CHON_NHAN_DON: {
            state.danhSachDonHangDaChon = action?.danhSachDonHangDaChon
            state.orderEdit = action?.orderEdit
            return { ...state }
        }

        case SET_DON_HANG_EDIT_NHAN_DON: {
            state.donHangEdit = action?.record
            return { ...state }
        }

        default:
            return state
    }
}
