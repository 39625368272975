import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm, message, Input, Tooltip } from "antd";
import {
  FormOutlined,
  UserAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { SET_CONTENT_DRAWER } from "../../redux/types/GlobalType";
import {
  LAY_DANH_SACH_KHACH_HANG_SAGA,
  SET_EDIT_KHACH_HANG,
  XOA_KHACH_HANG_SAGA,
} from "../../redux/types/KhachHangType";
import FormThemKhachHang from "../../components/Forms/KhachHang/FormThemKhachHang";
import FormEditKhachHang from "../../components/Forms/KhachHang/FormEditKhachHang";

export default function KhachHang(props) {
  const [dataSearch, setDataSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const dispatch = useDispatch();
  const { danhSachKhachHang, total } = useSelector(
    (state) => state.KhachHangReducer
  );

  const fetchData = (page, pageSize, keyword) => {
    dispatch({
      type: LAY_DANH_SACH_KHACH_HANG_SAGA,
      payload: { page, pageSize, keyword },
    });
  };

  useEffect(() => {
    fetchData(page, pageSize, dataSearch || "");
  }, []);

  const handleSearch = () => {
    setPage(1);
    fetchData(1, pageSize, dataSearch || "");
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Tài Khoản");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Tên Khách Hàng",
      dataIndex: "TenKhachHang",
      key: "TenKhachHang",
    },
    {
      title: "Số Điện Thoại",
      dataIndex: "SoDienThoai",
      key: "SoDienThoai",
    },
    {
      title: "Link Facebook",
      key: "LinkFacebook",
      render: (text, record, index) => {
        return (
          <>
            <a target="_blank" href={record?.LinkFacebook}>
              {record?.LinkFacebook}
            </a>
          </>
        );
      },
    },
    {
      title: "Địa Chỉ Nhận Hàng",
      dataIndex: "DiaChiNhanHang",
      key: "DiaChiNhanHang",
    },
    {
      title: "Chức Năng",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => {
        return (
          <div>
            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                const actionSetKhachHangEdit = {
                  type: SET_EDIT_KHACH_HANG,
                  khachHangEdit: record,
                };
                dispatch(actionSetKhachHangEdit);
                const actionSetContentDrawer = {
                  type: SET_CONTENT_DRAWER,
                  title: "Chỉnh Sửa thông Tin Khách Hàng",
                  Component: <FormEditKhachHang />,
                };
                dispatch(actionSetContentDrawer);
              }}
            >
              <Tooltip title="Chỉnh sửa thông tin khách hàng">
                <FormOutlined style={{ fontSize: 17 }} />
              </Tooltip>
            </button>
            <Popconfirm
              title="Xóa Khách Hàng"
              description={`Bạn có chắc chắn muốn xóa Khách Hàng "${record?.TenKhachHang}"?`}
              onConfirm={() => {
                dispatch({
                  type: XOA_KHACH_HANG_SAGA,
                  id: record?.Id,
                });
              }}
              onCancel={cancel}
              okText="Có"
              cancelText="Không"
            >
              <button className="btn btn-danger ml-2" danger>
                <Tooltip title="Xóa khách hàng">
                  <i className="fa-solid fa-trash"></i>
                </Tooltip>
              </button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Quản Lý Khách Hàng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            dispatch({
              type: SET_CONTENT_DRAWER,
              title: "Thêm Khách Hàng Mới",
              Component: <FormThemKhachHang />,
            });
          }}
        >
          <UserAddOutlined style={{ fontSize: 18 }} /> Thêm Khách Hàng
        </button>
      </div>

      <div className="ms-3">
        <div className="my-4 mt-3 ms-md-4 row">
          <Input
            onChange={(e) => {
              setDataSearch(e.target.value);
            }}
            className="col-md-9"
            placeholder="Tìm kiếm theo tên khách hàng ...."
            prefix={<SearchOutlined />}
          />
          <Button onClick={handleSearch} className="col-md-2 ml-md-3 mt-2 mt-md-0" type="primary">
            Tìm Kiếm
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachKhachHang}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          onChange: (newPage, newPageSize) => {
            setPage(newPage);
            setPageSize(newPageSize);
            fetchData(newPage, newPageSize, dataSearch || "");
          },
        }}
      />
    </div>
  );
}
