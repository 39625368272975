import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachNguonHangAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/don_hang/danh_sach_nguon_hang`);
}

const layThongTinKhachHangAPI = async (soDienThoai) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_hang/lay_thong_tin_khach_hang`, { soDienThoai });
}

const layDanhSachDonHangAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/don_hang/full_danh_sach_don_hang`);

}

const themDonHangAPI = async (data) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_hang/them_don_hang`, { ...data });
}

const layGiaTheoCanVaTiGiaAPI = async (idNguonHang) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_hang/lay_gia_theo_can_va_ti_gia`, { idNguonHang });
}

const xoaDonHangAPI = async (id) => {
    return await authorizedAxiosInstance.delete(`${DOMAIN}/don_hang/xoa_don_hang/${id}`);
}

const layDanhSachDonHangPhanTrangAPI = async (page = 1, pageSize = 30, tenKhachHang = '', soDienThoai = '', idTinhTrang = '', idNguonHang = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_hang/lay_danh_sach_don_hang_by_keyword_phan_trang`, {
        page,
        pageSize,
        tenKhachHang,
        soDienThoai,
        idTinhTrang,
        idNguonHang
    });
}

const chinhSuaDonHangAPI = async (id, data) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/don_hang/sua_don_hang/${id}`, { ...data });
}

const thayDoiTrangThaiThanhToanAPI = async (id) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/don_hang/thay_doi_trang_thai_thanh_toan/${id}`);
}

const layDanhSachTinhTrangDonHangAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/don_hang/danh_sach_tinh_trang_don_hang`);
}


export const donHangService = {
    layDanhSachNguonHangAPI,
    layThongTinKhachHangAPI,
    layDanhSachDonHangAPI,
    themDonHangAPI,
    layGiaTheoCanVaTiGiaAPI,
    xoaDonHangAPI,
    layDanhSachDonHangPhanTrangAPI,
    chinhSuaDonHangAPI,
    thayDoiTrangThaiThanhToanAPI,
    layDanhSachTinhTrangDonHangAPI,
}