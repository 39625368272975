import { Input, Button } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../redux/types/GlobalType";
import { CHINH_SUA_NGUOI_DUNG_SAGA } from "../../redux/types/QuanTriType";

function FormEditUser(props) {
  const dispatch = useDispatch();
  const { userEdit } = useSelector((state) => state.QuanTriReducer);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="container">
      <h6 className="ms-1">User Id</h6>
      <div className="d-flex">
        <Input
          name="Id"
          disabled
          value={values.Id}
          size="large"
          placeholder="Id"
          prefix={<UserOutlined />}
          onChange={handleChange}
        />
      </div>
      <h6 className="ms-1 mt-3">Mã nhân Viên</h6>
      <div className="d-flex">
        <Input
          name="maNhanVien"
          value={values.maNhanVien}
          size="large"
          placeholder="Mã nhân Viên"
          prefix={<UserOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.maNhanVien}</div>

      <h6 className="mt-3 ml-1">Tên Nhân Viên</h6>
      <div className="d-flex">
        <Input
          value={values.tenNhanVien}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="tenNhanVien"
          size="large"
          placeholder="Tên Nhân Viên"
          prefix={<UserOutlined />}
        />
      </div>
      <div className="text-danger">{errors.tenNhanVien}</div>

      <h6 className="mt-3 ml-1">Username</h6>
      <div className="d-flex">
        <Input
          value={values.username}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="username"
          size="large"
          disabled
          placeholder="Username"
          prefix={<UserOutlined />}
        />
      </div>
      <div className="text-danger">{errors.username}</div>

      <h6 className="mt-3 ml-1">Mật Khẩu Mới</h6>
      <div className="d-flex">
        <Input
          onChange={handleChange}
          value={values.matKhau}
          style={{ width: "100%", minWidth: 450 }}
          type={passwordVisible ? "text" : "password"}
          name="matKhau"
          size="large"
          placeholder="Mật Khẩu Mới"
          prefix={<LockOutlined />}
        />
        <Button
          style={{}}
          icon={passwordVisible ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
          onClick={() => setPasswordVisible(!passwordVisible)}
        />
      </div>
      <div className="text-danger">{errors.matKhau}</div>
    </form>
  );
}

const FormEditUserFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return {
      Id: props?.userEdit?.Id,
      maNhanVien: props?.userEdit?.MaNhanVien,
      tenNhanVien: props?.userEdit?.TenNhanVien,
      username: props?.userEdit?.Username,
      matKhau: "",
    };
  },
  validationSchema: Yup.object().shape({
    maNhanVien: Yup.string().required("mã nhân viên bắt buộc"),
    username: Yup.string().required("username bắt buộc"),
    tenNhanVien: Yup.string()
      .max(40)
      .required("Tên nhân viên bắt buộc phải nhập"),
    matKhau: Yup.string()
      .min(6, "mật khẩu ít nhất 6 kí tự")
      .max(32, "mật khẩu nhiều nhất 32 kí tự")
      .required("Mật khẩu bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    setSubmitting(false);
    // console.log(values);
    resetForm({
      values: "",
    });

    props.dispatch({
      type: CHINH_SUA_NGUOI_DUNG_SAGA,
      userUpdate: { ...values, password: values.matKhau },
    });
  },
})(FormEditUser);

const mapStateToProp = (state) => {
  return {
    userEdit: state.QuanTriReducer?.userEdit,
  };
};

export default connect(mapStateToProp)(FormEditUserFormik);
