import React from "react";
import { Link } from 'react-router-dom';
import './NotFoundPage.css';
export default function NotFound(props) {
  return (
    <>
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-description">
          Oops! The page you are looking for does not exist.
        </p>
        <Link to="/" className="not-found-link">
          Go back to Home
        </Link>
      </div>
    </>
  );
}
