import { SET_EDIT_NGUON_HANG, SET_NGUON_HANG } from "../types/NguonHangType";

const initialState = {
    danhSachNguonHang: [
    ],
    nguonHangEdit: {
        "Id": 4,
        "Ten": "HM",
        "Link": "https://www2.hm.com/vi_vn/index.html",
        "GiaTheoCan": "5000",
        "xPathAnh": null,
        "xPathGiaWeb": null,
        "GhiChu": "Nước Ngoài",
        "IdLoaiTienTe": 3
    }
}

export const NguonHangReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_NGUON_HANG: {
            state.danhSachNguonHang = action.danhSachNguonHang;
            return { ...state }
        }

        case SET_EDIT_NGUON_HANG: {
            state.nguonHangEdit = action?.nguonHangEdit;
            return { ...state }
        }

        default:
            return state
    }
}
