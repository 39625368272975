import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachVaiTroAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/role/lay_danh_sach_vai_tro`);
}

const thayDoiVaiTroAPI = async (id, roles) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/role/thay_doi_vai_tro/${id}`, { roles })
}

export const roleService = {
    layDanhSachVaiTroAPI,
    thayDoiVaiTroAPI
}