import dayjs from "dayjs"
import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const laythongKeDoanhThuChiPhiAPI = async (url, startDate, endDate) => {
    return authorizedAxiosInstance.post(`${DOMAIN}${url}`, {
        startDate,
        endDate
    })
}

const layThongKeDoanhThuTheoNhanVienAPI = async (startDate, endDate) => {
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    return authorizedAxiosInstance.post(`${DOMAIN}/thong_ke_bao_cao/doanh_thu_theo_nhan_vien`, {
        startDate,
        endDate
    })
}

const layThongKeNhomChiPhiAPI = async (startDate, endDate) => {
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    return authorizedAxiosInstance.post(`${DOMAIN}/thong_ke_bao_cao/thong_ke_theo_nhom_chi_phi`, {
        startDate,
        endDate
    })
}

const layThongKeTheoKhachHangAPI = async (startDate, endDate) => {
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    return authorizedAxiosInstance.post(`${DOMAIN}/thong_ke_bao_cao/thong_ke_theo_khach_hang`, {
        startDate,
        endDate
    })
}

export const thongKeBaoCaoService = {
    laythongKeDoanhThuChiPhiAPI,
    layThongKeDoanhThuTheoNhanVienAPI,
    layThongKeNhomChiPhiAPI,
    layThongKeTheoKhachHangAPI,
}