import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Popconfirm,
  message,
  Popover,
  AutoComplete,
  Divider,
  Flex,
  Tooltip,
  Input,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { SET_CONTENT_DRAWER } from "../../redux/types/GlobalType";
import {
  LAY_DANH_SACH_KHACH_HANG_SAGA,
  SET_EDIT_KHACH_HANG,
} from "../../redux/types/KhachHangType";
import FormEditKhachHang from "../../components/Forms/KhachHang/FormEditKhachHang";
import {
  LAY_DANH_SACH_TIEN_TE_SAGA,
  SET_EDIT_TIEN_TE,
} from "../../redux/types/TienTeType";
import FormThayDoiTiGia from "../../components/Forms/TienTe/FormThayDoiTiGia";

export default function ThayDoiTyGia(props) {
  const [dataSearch, setDataSearch] = useState("");

  const dispatch = useDispatch();
  const { danhSachTienTe } = useSelector((state) => state.TienTeReducer);

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_TIEN_TE_SAGA });
  }, []);

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  const handleChange = (pagination, filters, sorter) => {
    setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearFilters = () => {
    setState({ filteredInfo: null });
  };

  const clearAll = () => {
    setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  const setAgeSort = () => {
    setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};
  const columns = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      sorter: (item2, item1) => {
        return item2.Id - item1.Id;
      },
      sortDirections: ["descend"],
    },
    {
      title: "Loại Tiền Tệ",
      dataIndex: "TienTe",
      key: "TienTe",
    },
    {
      title: "Tỉ Giá",
      dataIndex: "TiGia",
      key: "TiGia",
    },

    {
      title: "Chức Năng",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => {
        return (
          <div>
            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                // gửi dữ liệu hiện tại lên KhachHangReducer
                const actionSetKhachHangEdit = {
                  type: SET_EDIT_TIEN_TE,
                  tienTeEdit: record,
                };
                dispatch(actionSetKhachHangEdit);
                // set content drawer
                const actionSetContentDrawer = {
                  type: SET_CONTENT_DRAWER,
                  title: "Chỉnh Sửa Tỉ Giá",
                  Component: <FormThayDoiTiGia />,
                };
                dispatch(actionSetContentDrawer);
              }}
            >
              <FormOutlined style={{ fontSize: 17 }} />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h3 className="col-9">Thay Đổi Tỷ giá</h3>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={danhSachTienTe}
        onChange={handleChange}
      />
    </div>
  );
}
