import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';

import { Notification } from '../../utils/Notifications/Notification';
import { CHINH_SUA_CHI_PHI_SAGA, LAY_DANH_SACH_CHI_PHI_SAGA, LAY_DANH_SACH_NHOM_CHI_PHI_SAGA, SET_DANH_SACH_CHI_PHI, SET_DANH_SACH_NHOM_CHI_PHI, THEM_CHI_PHI_SAGA, XOA_CHI_PHI_SAGA } from '../types/ChiPhiType';
import { chiPhiService } from '../../services/chiPhiService';
import { history } from '../../utils/constants/History';


function* layDanhSachNhomChiPhiSaga() {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return chiPhiService.layDanhSachNhomChiPhiAPI();
        })
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_NHOM_CHI_PHI,
                danhSachNhomChiPhi: data?.data?.danhSachNhomChiPhi,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách nhóm chi Phí thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachNhomChiPhiSaga() {
    yield takeLatest(LAY_DANH_SACH_NHOM_CHI_PHI_SAGA, layDanhSachNhomChiPhiSaga)
}

function* layDanhChiPhiSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return chiPhiService.layDanhSachChiPhiAPI(page, pageSize, filters?.idNhomChiPhi, filters?.startDate, filters?.endDate);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_CHI_PHI,
                danhSachChiPhi: data?.data?.data,
                totalCount: data?.data?.totalCount,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhChiPhiSaga() {
    yield takeLatest(LAY_DANH_SACH_CHI_PHI_SAGA, layDanhChiPhiSaga)
}

function* xoaCHiPhiSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return chiPhiService.xoaChiPhiAPI(action?.id);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_CHI_PHI_SAGA,
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa Chi Phí thành công!");
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa Chi Phí thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiXoaCHiPhiSaga() {
    yield takeLatest(XOA_CHI_PHI_SAGA, xoaCHiPhiSaga)
}

function* themChiPhiSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {

        const { data, status } = yield call(() => {
            return chiPhiService.themChiPhiAPI(action?.newChiPhi);
        });


        if (status == STATUS_CODE.CREATED) {
            Notification(NOTIFICATION_TYPE.success, "Thêm Chi Phí Thành Công!")
            action.callback(true);
            history.push('/chi-phi');
        }
    } catch (error) {
        action.callback(false);
        Notification(NOTIFICATION_TYPE.error, "Thêm Chi Phí Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiThemChiPhiSaga() {
    yield takeLatest(THEM_CHI_PHI_SAGA, themChiPhiSaga)
}

function* chinhSuaChiPhiSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {

        const { data, status } = yield call(() => {
            return chiPhiService.chinhSuaChiPhiAPI(action?.chiPhiEdit);
        });

        if (status == STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Chi Phí Thành Công!")
            history.push('/chi-phi');
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Chi Phí Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaChiPhiSaga() {
    yield takeLatest(CHINH_SUA_CHI_PHI_SAGA, chinhSuaChiPhiSaga)
}