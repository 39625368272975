import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA, LAY_DANH_SACH_CHON_NHAN_DON_SAGA, LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA, LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA, SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON, SET_DANH_SACH_ORDER_CHON_NHAN_DON, SET_DANH_SACH_ORDER_TRANG_NHAN_DON, XAC_NHAN_DA_NHAN_HANG_SAGA } from '../types/NhanDonType';
import { Notification } from '../../utils/Notifications/Notification';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { nhanDonService } from '../../services/nhanDonService';
import { CLEAN_CONTENT_DRAWER } from '../types/GlobalType';
import { history } from '../../utils/constants/History';

function* layDanhSachNguonHangTrangNhanDonSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return nhanDonService.layDanhSachNguonHangAPI();
        })

        // console.log(data);
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON,
                danhSachNguonHang: data?.data?.danhSachNguonHang,
            })
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Nguồn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachNguonHangTrangNhanDonSaga() {
    yield takeLatest(LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA, layDanhSachNguonHangTrangNhanDonSaga)
}

function* layDanhOrderTrangNhanDonSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return nhanDonService.layDanhSachOrderAPI(page, pageSize, filters?.idNguonHang, filters?.maOrder, filters?.trackingNumber);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_ORDER_TRANG_NHAN_DON,
                danhSachOrder: data?.data?.data,
                totalCountOrder: data?.data?.totalCount,
            })
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách order thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhOrderTrangNhanDonSaga() {
    yield takeLatest(LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA, layDanhOrderTrangNhanDonSaga)
}

function* layDanhSachDonHangOrderNhanDonSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return nhanDonService.layOrderChiTietAPI(action?.id);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_ORDER_CHON_NHAN_DON,
                danhSachDonHangDaChon: data?.data?.danhSachDonHangDaChon,
                orderEdit: data?.data?.orderChiTiet,
            })
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "Lấy Thông tin chi tiết Order Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonHangOrderNhanDonSaga() {
    yield takeLatest(LAY_DANH_SACH_CHON_NHAN_DON_SAGA, layDanhSachDonHangOrderNhanDonSaga)
}

function* chinhSuaDonHangOrderNhanHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { donHangEdit } = action;
        const { data, status } = yield call(() => {
            return nhanDonService.chinhSuaOrderAPI(donHangEdit?.idOrder, donHangEdit?.idDonHang, donHangEdit?.idTinhTrang, donHangEdit?.canNang, donHangEdit?.tongTienCan);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Đơn Hàng ORDER Thành Công!");
            yield delay(300)
            window.location.reload();
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "chỉnh sửa đơn hàng Order Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaDonHangOrderNhanHangSaga() {
    yield takeLatest(CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA, chinhSuaDonHangOrderNhanHangSaga)
}

function* xacNhanDaNhanHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return nhanDonService.xacNhanDaNhanHangAPI(action?.idOrder);
        })


        if (status == STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Xác Nhận Đã Nhận Được Hàng Thành Công!");
            yield delay(300)
            window.location.reload();
        }

    } catch (error) {

        Notification(NOTIFICATION_TYPE.error, "Xác Nhận đã Nhận Được Hàng Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiXacNhanDaNhanHangSaga() {
    yield takeLatest(XAC_NHAN_DA_NHAN_HANG_SAGA, xacNhanDaNhanHangSaga)
}

