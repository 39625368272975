import React from 'react'
import { Layout } from 'antd';
const { Footer } = Layout;
export default function FooterLayout(props) {
    return (
        <Footer
            style={{
                textAlign: 'center',
            }}
        >
            Mẹ Gấu ©{new Date().getFullYear()} Created by team Promise
        </Footer>
    )
}
