import React from 'react'
import { CLEAN_CONTENT_DRAWER, CLOSE_DRAWER, OPEN_DRAWER, OPEN_FORM_CREATE_USER, SET_CONTENT_DRAWER, SET_SUBMIT } from '../types/GlobalType'
const initialState = {
    visible: false,
    title: '',
    ComponentContentDrawer: <p>default</p>,
    callBackSubmit: (propsValue) => { alert('click demo!') }
}

export const DrawerReducer = (state = initialState, action) => {
    switch (action.type) {

        case OPEN_DRAWER:
            return { ...state, visible: true }

        case CLOSE_DRAWER: {
            state.visible = false;
            state.ComponentContentDrawer = "";
            state.title = "";
            return { ...state }

        }

        case OPEN_FORM_CREATE_USER: {
            state.visible = true;
            state.ComponentContentDrawer = action.Component;
            state.title = action.title;
            return { ...state }

        }

        case SET_CONTENT_DRAWER: {
            state.visible = true;
            state.ComponentContentDrawer = action.Component;
            state.title = action.title;
            return { ...state }

        }

        case CLEAN_CONTENT_DRAWER: {
            state.visible = false;
            state.ComponentContentDrawer = "";
            state.title = "";
            return { ...state }
        }

        case SET_SUBMIT: {
            return { ...state, callBackSubmit: action.submitFunction }
        }

        default:
            return state
    }
}