export const LAY_DANH_SACH_NGUOI_DUNG = 'LAY_DANH_SACH_NGUOI_DUNG'
export const SET_USER_EDIT = "SET_USER_EDIT"
export const SET_DANH_SACH_VAI_TRO = 'SET_DANH_SACH_VAI_TRO'

// saga

export const LAY_DANH_SACH_NGUOI_DUNG_SAGA = "LAY_DANH_SACH_NGUOI_DUNG_SAGA"

export const THEM_MOI_NGUOI_DUNG_SAGA = 'THEM_MOI_NGUOI_DUNG_SAGA'

export const CHINH_SUA_NGUOI_DUNG_SAGA = 'CHINH_SUA_NGUOI_DUNG_SAGA'

export const THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA = 'THAY_DOI_TRANG_THAI_NGUOI_DUNG_SAGA'

export const LAY_DANH_SACH_KEYWORD_SAGA = 'LAY_DANH_SACH_KEYWORD_SAGA';