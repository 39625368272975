import { applyMiddleware, combineReducers, createStore } from 'redux';
import reduxThunk from 'redux-thunk'
import createMiddleWareSaga from 'redux-saga'
import { rootSaga } from "./sagas/rootSaga";
import LoadingReducer from './reducers/LoadingReducer';
import { QuanTriReducer } from './reducers/QuanTriReducer';
import { DrawerReducer } from './reducers/DrawerReducer';
import { KhachHangReducer } from './reducers/KhachHangReducer';
import { TienTeReducer } from './reducers/TienTeReducer';
import { NguonHangReducer } from './reducers/NguonHangReducer';
import { DonHangReducer } from './reducers/DonHangReducer';
import { OrderReducer } from './reducers/OderReducer';
import { NhanDonReducer } from './reducers/NhanDonReducer';
import { DonShipReducer } from './reducers/DonShipReducer';
import { KetThucReducer } from './reducers/KetThucReducer';
import { ChiPhiReducer } from './reducers/ChiPhiReducer';

const middleWareSaga = createMiddleWareSaga();

const rootReducer = combineReducers({
    LoadingReducer,
    QuanTriReducer,
    DrawerReducer,
    KhachHangReducer,
    TienTeReducer,
    NguonHangReducer,
    DonHangReducer,
    OrderReducer,
    NhanDonReducer,
    DonShipReducer,
    KetThucReducer,
    ChiPhiReducer,
});



const store = createStore(rootReducer, applyMiddleware(reduxThunk, middleWareSaga));
middleWareSaga.run(rootSaga);

export default store;