import React from 'react'
import { Button, Input } from 'antd';
import { UserOutlined, LockOutlined, TwitterOutlined } from '@ant-design/icons';
import { withFormik } from 'formik';
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { loginAction } from '../../redux/actions/LoginAction';
import logo from '../../assets/imageLogo/logo.jpg'

function Login(props) {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = props;
    return (
        <form onSubmit={handleSubmit} className='container' style={{ height: window.innerHeight }}>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: window.innerHeight }} >
                <img src={logo} alt="logo" style={{ width: '250px', marginBottom: '10px' }} />
                <h3 className="text-center" style={{ fontWeight: 700, fontSize: 35 }}>Đăng Nhập Mẹ Gấu</h3>
                <div className="d-flex mt-3" >
                    <Input onChange={handleChange} style={{ width: '100%', minWidth: 300 }} name="username" size="large" placeholder="Tên Đăng Nhập" prefix={<UserOutlined />} />
                </div>
                <div className='text-danger'>{errors.username}</div>
                <div className="d-flex mt-3">
                    <Input onChange={handleChange} style={{ width: '100%', minWidth: 300 }} type="password" name="password" size="large" placeholder="Mật Khẩu" prefix={<LockOutlined />} />
                </div>
                <div className='text-danger'>{errors.password}</div>


                <Button htmlType='submit' size="large" style={{ minWidth: 300, backgroundColor: 'rgb(102,117,223)', color: '#fff' }} className="mt-5">Login</Button>


                {/* <div className="social mt-3 d-flex">
                    <Button style={{ backgroundColor: 'rgb(59,89,152)' }} shape="circle" size={"large"}>
                        <span className="font-weight-bold" style={{ color: '#fff' }} >
                            <i className="fa-brands fa-facebook"></i>
                        </span>
                    </Button>
                    <Button type="primary ms-3" shape="circle" icon={<TwitterOutlined />} size={"large"}>
                    </Button>
                </div> */}
            </div>
        </form>
    )
}


const LoginWithFormik = withFormik({
    mapPropsToValues: () => ({
        username: '',
        password: ''
    }),
    validationSchema: Yup.object().shape({
        username: Yup.string().required("Username is required!"),
        password: Yup.string().min(6, 'password must have min 6 character!').max(32, 'password must have max 32 character')
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.dispatch(loginAction(values.username, values.password));
    },

})(Login);

export default connect()(LoginWithFormik);