import React, { useEffect } from "react";
import { withFormik, Form } from "formik";
import { Checkbox, Button, Tag } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../redux/types/GlobalType";
import { THAY_DOI_VAI_TRO_SAGA } from "../../redux/types/VaiTroType";

const colors = ["success", "processing", "error", "warning", "default"];

const RoleSelectionForm = (props) => {
  const dispatch = useDispatch();
  const rolesFromBackend = useSelector(
    (state) => state.QuanTriReducer?.danhSachVaiTro
  );

  const { values, errors, handleSubmit, setFieldValue } = props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  const handleRoleChange = (checkedValues) => {
    setFieldValue("roles", checkedValues);
  };

  return (
    <Form onSubmit={handleSubmit} className="container">
      <h6 className="mt-3 ml-1">Chọn vai trò</h6>
      <Checkbox.Group
        value={values.roles}
        onChange={handleRoleChange}
        style={{ display: "flex", flexDirection: "column", gap: "12px" }} // Add gap between checkboxes
      >
        {rolesFromBackend.map((role, index) => (
          <Checkbox
            key={role.Id}
            value={role.Id}
            style={{
              marginBottom: "8px",
              fontSize: "16px", // Increase font size for the checkbox
              lineHeight: "24px", // Increase line height for better spacing
            }}
          >
            <Tag
              key={index}
              color={colors[index % colors.length]}
              style={{
                fontSize: "16px", // Increase font size for the tag
                padding: "5px 10px", // Add padding for better appearance
              }}
            >
              {role.Role}
            </Tag>
          </Checkbox>
        ))}
      </Checkbox.Group>
      <div className="text-danger">{errors.roles}</div>
    </Form>
  );
};

const FormCreateUserFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ selectedRoles, idEdit }) => ({
    id: idEdit || 0,
    roles: selectedRoles || [],
  }),
  validationSchema: Yup.object().shape({
    roles: Yup.array().of(Yup.number()).required("Vai trò bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    setSubmitting(false);
    resetForm({
      values: {
        roles: [],
      },
    });
    props.dispatch({
      type: THAY_DOI_VAI_TRO_SAGA,
      values,
    });
  },
})(RoleSelectionForm);

export default connect()(FormCreateUserFormik);
