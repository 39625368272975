import { Input, Button } from "antd";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  PhoneOutlined,
  BankOutlined,
  FacebookOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_SUBMIT } from "../../../redux/types/GlobalType";
import {
  CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA,
  THEM_KHACH_HANG_SAGA,
} from "../../../redux/types/KhachHangType";
import { CHINH_SUA_TI_GIA_SAGA } from "../../../redux/types/TienTeType";

function FormThayDoiTiGia(props) {
  const dispatch = useDispatch();

  const { khachHangEdit } = useSelector(
    (state) => state.KhachHangReducer.khachHangEdit
  );

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    props;

  useEffect(() => {
    dispatch({ type: SET_SUBMIT, submitFunction: handleSubmit });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="container">
      <h6 className="ms-1 mt-3">Id</h6>
      <div className="d-flex">
        <Input
          name="Id"
          disabled
          value={values.Id}
          size="large"
          placeholder="Id"
          prefix={<DollarOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.Id}</div>

      <h6 className="ms-1 mt-3">Loại Tiền Tệ</h6>
      <div className="d-flex">
        <Input
          name="TienTe"
          value={values.TienTe}
          size="large"
          disabled
          placeholder="Loại Tiền Tệ"
          prefix={<DollarOutlined />}
          onChange={handleChange}
        />
      </div>
      <div className="text-danger">{errors.TienTe}</div>

      <h6 className="mt-3 ml-1">Tỉ giá</h6>
      <div className="d-flex">
        <Input
          value={values.TiGia}
          onChange={handleChange}
          style={{ width: "100%", minWidth: 450 }}
          type="text"
          name="TiGia"
          size="large"
          placeholder="Tỉ Giá"
          prefix={<DollarOutlined />}
        />
      </div>
      <div className="text-danger">{errors.TiGia}</div>
    </form>
  );
}

const FormThayDoiTiGiaFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    // console.log(props?.khachHangEdit);
    return {
      Id: props?.tienTeEdit?.Id,
      TienTe: props?.tienTeEdit?.TienTe,
      TiGia: props?.tienTeEdit?.TiGia,
    };
  },
  validationSchema: Yup.object().shape({
    TiGia: Yup.number()
      .typeError("tỉ giá không đúng định dạng")
      .required("tỉ giá bắt buộc"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    setSubmitting(false);

    // console.log(values);
    resetForm({
      values: "",
    });

    // console.log(values);

    props.dispatch({
      type: CHINH_SUA_TI_GIA_SAGA,
      tiGiaUpdate: { ...values },
    });
  },
})(FormThayDoiTiGia);

const mapStateToProp = (state) => {
  return {
    tienTeEdit: state.TienTeReducer?.tienTeEdit,
  };
};

export default connect(mapStateToProp)(FormThayDoiTiGiaFormik);
