export const OPEN_FORM = 'OPEN_FORM';

// drawer

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const OPEN_FORM_CREATE_USER = 'OPEN_FORM_CREATE_USER'
export const SET_SUBMIT = 'SET_SUBMIT'
export const SET_CONTENT_DRAWER = 'SET_CONTENT_DRAWER'
export const CLEAN_CONTENT_DRAWER = 'CLEAN_CONTENT_DRAWER'
