import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"


const layDanhSachDonHangPhanTrangAPI = async (page = 1, pageSize = 30, tenKhachHang = '', soDienThoai = '', idTinhTrang = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/ket_thuc/lay_danh_sach_don_hang_by_keyword_phan_trang`, {
        page,
        pageSize,
        tenKhachHang,
        soDienThoai,
        idTinhTrang
    });
}

const thayDoiTrangThaiThanhToanAPI = async (id) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/ket_thuc/thay_doi_trang_thai_thanh_toan_don_hang/${id}`);
}

const chinhSuaTinhTrangDonHangAPI = async (idDonHang, idTinhTrang) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/ket_thuc/thay_doi_tinh_trang_don_hang/${idDonHang}`, { idTinhTrang });
}


export const ketThucService = {
    layDanhSachDonHangPhanTrangAPI,
    thayDoiTrangThaiThanhToanAPI,
    chinhSuaTinhTrangDonHangAPI,
}