import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
const { Content, Sider } = Layout;


export const UserLoginTemplate = (props) => {
    const [{ width, height }, setSize] = useState({
        width: Math.round(window.innerWidth),
        height: Math.round(window.innerHeight)
    });

    useEffect(() => {
        window.onresize = () => {
            setSize({
                width: Math.round(window.innerWidth),
                height: Math.round(window.innerHeight)
            })
        }
    }, [])
    const { Component, ...restProps } = props;
    return (<Route {...restProps} render={(propsRoute) => {
        return (<>
            <Layout>
                {width >= 1000 && <Sider width={Math.round(width / 2)} style={{ height: Math.round(height), backgroundImage: `url("../../assets/imageLogin/backgroundLogin.jpg")`, backgroundSize: '100%' }}>
                    <img style={{ width: "100%", height: "100%" }} src={require('../../assets/imageLogin/backgroundLogin.jpg')} />
                </Sider>}


                <Layout>
                    <Content>
                        <Component {...propsRoute} />
                    </Content>
                </Layout>


            </Layout>
        </>)
    }}
    />)
}
