
import { SET_DANH_SACH_CHI_PHI, SET_DANH_SACH_NHOM_CHI_PHI, SET_EDIT_CHI_PHI } from "../types/ChiPhiType";


const initialState = {
    danhSachChiPhi: [],
    totalCount: 0,
    danhSachNhomChiPhi: [],
    chiPhiEdit: {},
}

export const ChiPhiReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_CHI_PHI: {
            state.danhSachChiPhi = action.danhSachChiPhi;
            state.totalCount = action?.totalCount;
            return { ...state }
        }

        case SET_DANH_SACH_NHOM_CHI_PHI: {
            state.danhSachNhomChiPhi = action.danhSachNhomChiPhi;
            return { ...state }
        }

        case SET_EDIT_CHI_PHI: {
            state.chiPhiEdit = action?.chiPhiEdit;
            return { ...state }
        }

        default:
            return state
    }
}
