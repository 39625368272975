import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Popconfirm,
  message,
  Popover,
  AutoComplete,
  Divider,
  Flex,
  Tooltip,
  Input,
  Checkbox,
  Select,
  Modal,
} from "antd";
import moment from "moment";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { STATUS_CODE } from "../../utils/settings/config";
import {
  CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_KET_THUC_SAGA,
  LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
} from "../../redux/types/KetThucType";
import { ketThucService } from "../../services/ketThucService";
const { Option } = Select;
const { confirm } = Modal;

export default function KetThuc(props) {
  const [dataSearch, setDataSearch] = useState("");

  const dispatch = useDispatch();
  const { danhSachDonHang, totalCount, danhSachTinhTrangDonHang } = useSelector(
    (state) => state.KetThucReducer
  );

  const [filters, setFilters] = useState({
    customerName: "",
    customerPhone: "",
    orderStatus: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  useEffect(() => {
    // Fetch initial list of orders
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, []); // Run only on mount

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset to first page on new search
    setPagination(newPagination); // Update the pagination state

    // Dispatch the action with new pagination and filters
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
      page: newPagination.current,
      pageSize: newPagination.pageSize,
      filters,
    });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleUpdatePaymentStatus = async (record, checked) => {
    try {
      // Gọi API thay đổi trạng thái thanh toán
      const { data, status } =
        await ketThucService.thayDoiTrangThaiThanhToanAPI(record?.Id);

      // Kiểm tra xem response có thành công hay không
      if (status == STATUS_CODE.SUCCESS) {
        message.success(
          `Cập nhật trạng thái thanh toán cho đơn hàng ${record.MaDonHang} thành công.`
        );
        dispatch({
          type: LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
          page: pagination?.current,
          pageSize: pagination?.pageSize,
          filters,
        });
        // Cập nhật trạng thái của đơn hàng trong UI nếu cần
      } else {
        message.error("Cập nhật trạng thái thất bại.");
      }
    } catch (error) {
      // Xử lý lỗi nếu có lỗi xảy ra
      message.error("Có lỗi xảy ra khi cập nhật trạng thái.");
    }
  };
  const columns = [
    {
      title: "Mã Đơn Hàng",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "125px",
                height: "125px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Thông Tin Sản phẩm",
      key: "LinkSanPham",
      width: 200,
      render: (text, record) => (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "10px" }}>
              <div>
                Tên SP:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.TenSanPham}
                </span>
              </div>
              <div>
                Màu:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.MauSanPham}
                </span>
              </div>
              <div>
                Size:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.Size}
                </span>
              </div>
              <div>
                Cân Nặng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
              </div>
              <div>
                Số Lượng:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>
              <div>
                Giá Web:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>
              </div>
              <div>
                CodeOff:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.CodeOff}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thông Tin Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div className="row">
              Hình Thức Thanh Toán:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {record?.TenHinhThucThanhToan}
                </span>
              </span>
            </div>
            <div className="row">
              Giá Báo Khách:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div className="row">
              Tiền Cọc:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div className="row">
              Tổng Tiền Cân:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Thông Tin Về thời gian",
      dataIndex: "NgayHenTraSanPham",
      key: "NgayHenTraSanPham",
      render: (text, record) => {
        return (
          <>
            <div>
              Ngày Tạo:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {moment(record?.NgayTao).format("DD/MM/YYYY")}
              </span>
            </div>
            <div>
              Ngày Hẹn Trả SP:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {moment(record?.NgayHenTraSanPham).format("DD/MM/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Thông Tin Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            Tên KH:{" "}
            <a target="_blank" href={record?.LinkFacebook}>
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.TenKhachHang}
              </span>
            </a>
          </div>
          <div>
            SĐT:{" "}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.SoDienThoai}
            </span>
          </div>

          <span>
            Địa Chỉ Nhận Hàng:
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.DiaChiNhanHang}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: "Ghi Chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Tình Trạng Đơn Hàng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            {record?.IdTinhTrang === 3 ||
            record?.IdTinhTrang === 4 ||
            record?.IdTinhTrang === 8 ||
            record?.IdTinhTrang === 9 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
            <Button
              size="small"
              type="primary"
              shape="circle"
              onClick={() => {
                let idTrangThai = 7;
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <h6>Chọn Trình Trạng Đơn Hàng {record?.MaDonHang}</h6>
                      <Select
                        style={{ width: "100%" }}
                        defaultValue={idTrangThai}
                        placeholder="Tình trạng Đơn Hàng"
                        onChange={(value) => {
                          idTrangThai = value;
                        }}
                      >
                        <Select.Option value={6}>Đã Ship Đi</Select.Option>
                        <Select.Option value={7}>Đã hoàn thành</Select.Option>
                        <Select.Option value={8}>Trả hàng</Select.Option>
                      </Select>
                      <h6 className="mt-2 text text-warning">
                        Bạn có chắc chắn muốn thay đổi tình trạng đơn Hàng{" "}
                        {record?.MaDonHang} không?
                      </h6>
                    </>
                  ),
                  onOk() {
                    dispatch({
                      type: CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_KET_THUC_SAGA,
                      idDonHang: record?.Id,
                      idTrangThai,
                      page: pagination?.current,
                      pageSize: pagination?.pageSize,
                      filters,
                    });
                  },
                  onCancel() {
                    //   console.log("Cancel");
                  },
                });
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>
          </>
        );
      },
    },
    {
      title: "Chức Năng",
      key: "x",
      render: (text, record) => (
        <div>
          <Popconfirm
            title={`Bạn có chắc chắn muốn thay đổi trạng thái thanh toán cho đơn hàng ${record.MaDonHang}?`}
            onConfirm={() => handleUpdatePaymentStatus(record)}
            okText="Có"
            cancelText="Không"
          >
            <Checkbox
              checked={record.XacNhanThanhToan}
              onChange={(e) => e.stopPropagation()}
              style={{ marginLeft: "5px" }}
            >
              Đã Thanh Toán
            </Checkbox>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Kết Thúc</h3>
      </div>

      <div className="search-filters my-4 mt-3 ms-4 row">
        <Input
          className="col-md-3"
          placeholder="Tìm kiếm theo tên khách hàng"
          value={filters.customerName}
          onChange={(e) =>
            setFilters({ ...filters, customerName: e.target.value })
          }
          prefix={<SearchOutlined />}
        />
        <Input
          className="col-md-3 ms-md-2"
          placeholder="Tìm kiếm theo SĐT khách hàng"
          value={filters.customerPhone}
          onChange={(e) =>
            setFilters({ ...filters, customerPhone: e.target.value })
          }
          prefix={<SearchOutlined />}
        />
        <Select
          className="col-md-3"
          placeholder="Tình trạng đơn hàng"
          value={filters.orderStatus}
          onChange={(value) => setFilters({ ...filters, orderStatus: value })}
        >
          <Select.Option value="">Tất cả</Select.Option>
          {danhSachTinhTrangDonHang?.map((item, key) => {
            return (
              <Select.Option value={item?.Id} key={key}>
                {item?.Ten}
              </Select.Option>
            );
          })}
        </Select>
        <Button className="col-md-2" type="primary" onClick={handleSearch}>
          Tìm Kiếm
        </Button>
      </div>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={danhSachDonHang}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
