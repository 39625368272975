import axios from 'axios'
import { authService } from './authService';
import { ACCESS_TOKEN, NOTIFICATION_TYPE, REFRESH_TOKEN, USER_INFO } from '../utils/settings/config';
import { history } from '../utils/constants/History';
import { Notification } from '../utils/Notifications/Notification';
// import { handleLogoutAPI, refreshTokenAPI } from '~/api';

let authorizedAxiosInstance = axios.create()

authorizedAxiosInstance.defaults.timeout = 1000 * 60 * 10
// authorizedAxiosInstance.defaults.withCredentials = true


// Add a request interceptor
authorizedAxiosInstance.interceptors.request.use((config) => {

    // Do something before request is sent

    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


let refreshTokenPromise = null;

// Add a response interceptor
authorizedAxiosInstance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response?.status === 401) {
        authService.logoutAPI()
    }

    const originalRequest = error.config
    // console.log("ori", originalRequest);

    if (error.response?.status === 410 && originalRequest) {
        // originalRequest._retry = true


        if (!refreshTokenPromise) {
            const refreshToken = localStorage.getItem(REFRESH_TOKEN);
            refreshTokenPromise = authService.refreshTokenAPI(refreshToken).then((res) => {
                const { accessToken } = res.data?.data
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                authorizedAxiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`
            }).catch((_error) => {
                localStorage.removeItem(USER_INFO);
                localStorage.removeItem(REFRESH_TOKEN);
                localStorage.removeItem(ACCESS_TOKEN);
                history.push("/login")


                return Promise.reject(_error);
            })
                .finally(() => {
                    refreshTokenPromise = null
                })
        }

        return refreshTokenPromise.then(() => {
            return authorizedAxiosInstance(originalRequest)

        })


    }


    // console.log(error);

    if (error.response?.status !== 410) {
        Notification(NOTIFICATION_TYPE.error, error.response?.data?.message || error?.message);
        // toast.error(error.response?.data?.message || error?.message);
    }

    return Promise.reject(error);
});

export default authorizedAxiosInstance;