export const SET_DANH_SACH_KHACH_HANG = 'SET_DANH_SACH_KHACH_HANG'
export const SET_EDIT_KHACH_HANG = 'SET_EDIT_KHACH_HANG';

// saga

export const LAY_DANH_SACH_KHACH_HANG_SAGA = 'LAY_DANH_SACH_KHACH_HANG_SAGA'

export const LAY_DANH_SACH_KHACH_HANG_THEO_KEYWORD_SAGA = 'LAY_DANH_SACH_KHACH_HANG_THEO_KEYWORD_SAGA'

export const THEM_KHACH_HANG_SAGA = 'THEM_KHACH_HANG_SAGA'

export const CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA = 'CHINH_SUA_THONG_TIN_KHACH_HANG_SAGA'

export const XOA_KHACH_HANG_SAGA = 'XOA_KHACH_HANG_SAGA'