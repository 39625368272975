import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachNhomChiPhiAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/chi_phi/lay_danh_sach_nhom_chi_phi`);
}

const layDanhSachChiPhiAPI = async (page = 1, pageSize = 30, idNhom = "", startDate = '', endDate = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/chi_phi/lay_danh_sach_chi_phi_by_keyword_phan_trang`, {
        page,
        pageSize,
        idNhom,
        startDate,
        endDate
    });
}

const xoaChiPhiAPI = async (id) => {
    return await authorizedAxiosInstance.delete(`${DOMAIN}/chi_phi/xoa_chi_phi/${id}`);
}

const themChiPhiAPI = async (newData) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/chi_phi/them_chi_phi`, { ...newData });
}

const chinhSuaChiPhiAPI = async (updateChiPhi) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/chi_phi/sua_chi_phi/${updateChiPhi?.id}`, { ...updateChiPhi });
}

export const chiPhiService = {
    layDanhSachNhomChiPhiAPI,
    layDanhSachChiPhiAPI,
    xoaChiPhiAPI,
    themChiPhiAPI,
    chinhSuaChiPhiAPI,
}