import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { EDIT_NGUON_HANG_SAGA, Lay_DANH_SACH_NGUON_HANG_SAGA, SET_NGUON_HANG, THEM_NGUON_HANG_SAGA, TIM_KIEM_NGUON_HANG_SAGA, XOA_NGUON_HANG_SAGA } from '../types/NguonHangType';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { nguonHangService } from '../../services/nguonHangService';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { Notification } from '../../utils/Notifications/Notification';
import { CLEAN_CONTENT_DRAWER } from '../types/GlobalType';

function* layDanhSachNguonHang() {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return nguonHangService.layDanhSachNguonHangAPI();
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_NGUON_HANG,
                danhSachNguonHang: data?.data?.danhSachNguonHang
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Nguồn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachNguonHang() {
    yield takeLatest(Lay_DANH_SACH_NGUON_HANG_SAGA, layDanhSachNguonHang)
}

function* timKiemNguonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    });

    try {
        const { data, status } = yield call(() => {
            return nguonHangService.timKiemNguonHangbyKeywordAPI(action?.keyword);
        })

        // console.log(data);


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_NGUON_HANG,
                danhSachNguonHang: data?.data?.danhSachNguonHang
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách khách hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiTimKiemNguonHangSaga() {
    yield takeLatest(TIM_KIEM_NGUON_HANG_SAGA, timKiemNguonHangSaga);
}

function* themNguonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log("saga", action);
        const { data, status } = yield call(() => {
            return nguonHangService.themNguonHangAPI(action?.newNguonHang);
        });

        // console.log(data);

        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: Lay_DANH_SACH_NGUON_HANG_SAGA
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Thêm Nguồn Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Thêm Nguồn Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiThemNguonHangSaga() {
    yield takeLatest(THEM_NGUON_HANG_SAGA, themNguonHangSaga)
}

function* xoaNguonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return nguonHangService.xoaNguonHangAPI(action?.id);
        })
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: Lay_DANH_SACH_NGUON_HANG_SAGA
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa Nguồn Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa Nguồn Hàng Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiXoasNguonHangSaga() {
    yield takeLatest(XOA_NGUON_HANG_SAGA, xoaNguonHangSaga);
}

function* chinhSuaNguonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        // console.log(action);
        const { nguonHangUpdate } = action;
        const { data, status } = yield call(() => {
            return nguonHangService.chinhSuaNguonHangAPI(nguonHangUpdate?.Id, nguonHangUpdate);
        })
        // console.log(data);
        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: Lay_DANH_SACH_NGUON_HANG_SAGA
            })
            yield put({
                type: CLEAN_CONTENT_DRAWER
            })
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Nguồn Hàng Thành Công!")
        }


    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Nguồn Thất Bại", error?.response?.data?.message);

    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaNguonHangSaga() {
    yield takeLatest(EDIT_NGUON_HANG_SAGA, chinhSuaNguonHangSaga);
}