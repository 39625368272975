export const SET_DANH_SACH_CHI_PHI = 'SET_DANH_SACH_CHI_PHI'

export const LAY_DANH_SACH_CHI_PHI_SAGA = 'LAY_DANH_SACH_CHI_PHI_SAGA';

export const SET_DANH_SACH_NHOM_CHI_PHI = 'SET_DANH_SACH_NHOM_CHI_PHI'

export const LAY_DANH_SACH_NHOM_CHI_PHI_SAGA = 'LAY_DANH_SACH_NHOM_CHI_PHI_SAGA'

export const XOA_CHI_PHI_SAGA = 'XOA_CHI_PHI_SAGA'

export const THEM_CHI_PHI_SAGA = 'THEM_CHI_PHI_SAGA'
export const SET_EDIT_CHI_PHI = 'SET_EDIT_CHI_PHI'

export const CHINH_SUA_CHI_PHI_SAGA = 'CHINH_SUA_CHI_PHI_SAGA'