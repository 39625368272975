import { authService } from "../../services/authService";
import { ACCESS_TOKEN, NOTIFICATION_TYPE, REFRESH_TOKEN, STATUS_CODE, USER_INFO } from "../../utils/settings/config";
import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { LOGIN_API, LOGOUT_API } from "../types/LoginType";
import { DISPLAY_LOADING, HIDE_LOADING } from "../types/LoadingType";
import { history } from "../../utils/constants/History";
import { Notification } from "../../utils/Notifications/Notification";


function* signinSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    });
    yield delay(800);
    try {

        const { data, status } = yield call(() => authService.loginAPI(action.userLogin));


        if (data?.status === STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Đăng nhập thành công");
            localStorage.setItem(ACCESS_TOKEN, data?.data?.accessToken);
            localStorage.setItem(REFRESH_TOKEN, data?.data?.refreshToken);
            localStorage.setItem(USER_INFO, JSON.stringify(data?.data?.user));
        } else {
            Notification(NOTIFICATION_TYPE.error, "Đăng nhập thất bại");
        }
        history.push("/")
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Đăng nhập thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiSigninSaga() {
    yield takeLatest(LOGIN_API, signinSaga);
}

function* logoutSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    });
    yield delay(500);
    try {

        const { data, status } = yield call(() => authService.logoutAPI());


        if (data?.status === STATUS_CODE.SUCCESS) {
            Notification(NOTIFICATION_TYPE.success, "Đăng Xuất Thành Công");
            localStorage.removeItem(USER_INFO);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(ACCESS_TOKEN);
        } else {
            Notification(NOTIFICATION_TYPE.error, "Đăng Xuất thất bại");
        }
        history.push("/login")
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Đăng Xuất thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLogoutSaga() {
    yield takeLatest(LOGOUT_API, logoutSaga);
}