import { DOMAIN } from "../utils/settings/config"
import authorizedAxiosInstance from "./authorizedAxios"

const layDanhSachTienTeAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/tien_te/danh_sach_loai_tien_te`);
}

const chinhSuaThongTinTienTeAPI = async (id, tiGia) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/tien_te/thay_doi_ti_gia_tien_te/${id}`, { tiGia })
}

export const tienTeService = {
    layDanhSachTienTeAPI,
    chinhSuaThongTinTienTeAPI,
}